@import "../../../resource/styles/variables/variables.module.scss";

.Servises_MainDiv {
  margin-top: 30px;
}

.ChooseUs_Head {
  margin: auto;
  width: 20%;
  padding: 6px 10px;
  font-size: $font-12;
  line-height: 24px;
  letter-spacing: 1.8px;
  color: $maintheme-color;
  font-weight: 500;
  background-color: $background-color;
  text-align: center;
  white-space: nowrap;
}

.Experience_Head {
  font-size: $font-36;
  line-height: 46.8px;
  letter-spacing: normal;
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
}

.Servises_Subdiv {
  display: flex;
  justify-content: space-around;
  margin: auto;
  padding-top: 30px;
}

.Experience_Card_Div {
  width: 30%;
}

.Card_div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;

  img {
    width: 20%;
    height: 50px;

    p {
      padding-left: 20px;
      font-weight: 400;
    }
  }

  div {
    padding-left: 20px;
  }
}

.Card_div div p:nth-child(1) {
  font-family: Prompt, sans-serif;
  font-size: $font-20;
  line-height: 24px;
  text-align: left;
  letter-spacing: normal;
  font-weight: 500;
}

.Card_div div p:nth-child(2) {
  font-family: Prompt, sans-serif;
  font-size: $font-14;
  line-height: 24px;
  text-align: left;
  letter-spacing: normal;
  color: $textcolor-four;
  padding-top: 10px;
}

@media only screen and (max-width: 1200px) {
  .Servises_Subdiv {
    display: block;
  }

  .Experience_Card_Div {
    width: 80%;
    margin: auto;
  }

  .Card_div {
    margin-top: 20px;
  }

  .ChooseUs_Head {
    background-color: $text-light;
  }
}
