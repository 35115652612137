@import "../../resource/styles/variables/variables.module.scss";

.bgImgDivLogin {
  background-image: url("../../Assets/Images/Main Sign Up Page Final.jpg");
  background-size: contain;
  background-repeat: no-repeat;
}

.bgImgDivLoginSP {
  background-image: url("../../Assets/Images/Login Page for Supply Partner new.jpg");
  background-size: contain;
  background-repeat: no-repeat;
}

.bgImgDivLoginLP {
  background-image: url("../../Assets/Images/Login Page for Load Partner new.jpg");
  background-size: contain;
  background-repeat: no-repeat;
}

.helplineNoLoginText {
  margin: 0;
  margin-top: 20px;
  text-align: center;
  font-size: $font-14;
  font-weight: 500;
  // color: $boxborder-color;
}
.loginpage {
  height: 100vh;

  .loginbox {
    width: 250px;
    align-items: center;
    background-color: $background-color;
    padding: 25px;
    margin: 50px 50px 0 0;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 0 38px -11px $bordershadow-color;
    float: right;

    .backDiv {
      margin-top: 4px;
      width: 30px;
      height: 25px;

      .IconsDiv {
        cursor: pointer;

        :hover {
          color: $primary-color;
        }

        .backFilters {
          margin: auto;
          font-size: $font-20;
        }
      }
    }
    .selctiondiv {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .boxcenter {
      display: flex;
      justify-content: center;
      position: relative;
      height: 100%;
      background: $background-color;

      .Icon-inside {
        width: 90%;
        margin: auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        margin-top: 15px;

        .form_icons {
          font-size: $font-18;
          color: $textcolor-four;
        }

        .eyeIcon {
          font-size: $font-22;
          color: $border-color;
          cursor: pointer;
        }
        i {
          position: absolute;
          left: 0;
          top: 6px;
          padding: 5px 15px 9px;
        }
      }

      .forgetPassDiv {
        display: flex;
        justify-content: flex-end;
        .forgetPassText {
          margin: 0;
          margin-top: 10px;
          font-size: 13px;
          font-weight: 500;
          color: $thirteen-color;
          cursor: pointer;
          display: inline-block;
          transition: transform 0.3s ease;

          &:hover {
            transform: scale(1.02);
          }
        }
      }

      .btndiv {
        margin-top: 15px;

        .container {
          width: 80%;
          max-width: 600px;
          margin: 0 auto;
          padding: 10px 10px;

          .otpdiv {
            display: flex;
          }
        }
      }
    }

    .TypesOfLoignDiv {
      margin-top: 20px;
      margin-bottom: 20px;
      .descDivlogin {
        padding: 20px;
        border-radius: 15px;
        box-shadow: $bordershadow2-color;
        margin-bottom: 20px;
        .radioBtnLogin {
          display: flex;
          justify-content: space-between;
          align-items: center;
          
          .radioLableLogin {
            margin: 0;
          }
          .radioInputLogin {
            width: 8%;
          }
        }
      }
    }

    .alternateLogin {
      display: flex;
      justify-content: center;
      .orLoginText {
        margin: 0;
        margin-top: 20px;
        font-size: 13px;
        font-weight: 500;
        color: $thirteen-color;
        cursor: pointer;
        display: inline-block;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.02);
        }
      }
    }

    .forgetPassFormDiv {
      .forgetPassFormHeading {
        text-align: center;
        font-weight: 500;
        font-size: $font-18;
        margin: 0;
      }

      .forgetPassFormSubHeading {
        margin: 0;
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: $font-14;
      }

      .submitBtnDiv {
        display: flex;
        justify-content: center;
        margin-top: 10px;
      }
    }

    .checkOtpDiv {
      .checkOtpHeading {
        margin: 0;
        font-weight: 500;
        text-align: center;
      }
      .checkOtpSubHeading {
        margin: 0;
        margin-top: 10px;
        text-align: justify;
        margin-bottom: 20px;
        font-size: $font-14;
      }

      .resendOtpTextDiv {
        display: flex;
        justify-content: flex-end;

        .resendOtpText {
          margin: 0;
          margin-top: 2dvh;
          font-size: $font-12;
        }
        .highlightText {
          text-decoration: underline;
          color: $thirteen-color;
          font-weight: 500;
          cursor: pointer;
        }
        .highlightSecText {
          color: $thirteen-color;
          font-weight: 500;
        }
      }
    }

    .otpDiv {
      .enterOtpDiv {
        display: flex;
        justify-content: center;
      }

      .submitBtnDiv {
        display: flex;
        justify-content: center;
        margin-top: 10px;
      }
    }

    .changePasswordDiv {
      .Icon-inside {
        width: 95%;
        margin: auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        margin-top: 15px;

        .form_icons {
          font-size: $font-18;
          color: $textcolor-four;
        }

        .eyeIcon {
          font-size: $font-22;
          color: $border-color;
          cursor: pointer;
        }
        i {
          position: absolute;
          left: 0;
          top: 6px;
          padding: 5px 15px 9px;
        }
      }

      .changePasswordHeading {
        margin: 0;
        text-align: center;
        font-weight: 500;
        font-size: $font-18;
      }

      .changePasswordSubHeading {
        margin: 0;
        margin-top: 10px;
        text-align: justify;
        font-size: $font-14;
      }
      .submitBtnDiv {
        display: flex;
        justify-content: center;
        margin-top: 10px;
      }
    }
  }
}

@media screen and (max-width: $screen-xs) {
  .loginpage {
    height: 845px;
  }
}

@media screen and (min-width: $screen-xs) and (max-width: $screen-sm) {
  .loginpage {
    height: 1025px;
  }
}
