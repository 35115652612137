@import "../../../../../resource/styles/variables/variables.module.scss";

.loadingCompleteModalDiv {
  border-top: 1px solid $boxborder-color;
  padding-left: 10px;

  .lastDiv {
    margin-bottom: 10px;
  }

  .eachModalContentDiv {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
    margin-top: 8px;

    .customSelectBox {
      width: 40%;
      .react-select {
        width: 100%;
      }
    }

    .contentHeading {
      margin: 0;
      width: 45%;
      font-size: $font-14;
      font-weight: 500;
    }

    .contentMainHeading{
      margin: 0;
      font-size: $font-14;
      font-weight: bold;
      color: $primary-color;
    }
  }
  
}
