@import "../../../../resource/styles/variables/variables.module.scss";

.ProfileStatusModalDiv {
  border-top: 1px solid $boxborder-color;

  .ProfileStatusModalMessageDiv {
    padding: 20px 10px;
    .profileErrDiv{
        font-size:$font-15;
        font-weight: 700;
    }
  }
 
  
}
