@import "../../../resource/./styles/variables/variables.module.scss";

.Allloadmaindiv {
  max-height: 70vh;
  overflow-y: scroll;

  .loadPostTypeInputDiv {
    width: 16%;
    margin-bottom: 10px;
    padding-left: 10px;

    .css-b62m3t-container {
      align-items: center;
      position: relative;

      .css-t3ipsp-control,
      .css-13cymwt-control {
        padding: 0;
        width: 100%;
        margin: auto;
        border: 0px;
        border-radius: 0px;
        border-bottom: 1px solid $border-color;
        min-height: 10px;
        justify-content: flex-start;
        box-shadow: none;
        cursor: pointer;

        .css-1fdsijx-ValueContainer {
          height: 21px;
          margin-left: -6px;
          padding: 0px 8px;
          font-size: $font-14;

          .css-1jqq78o-placeholder {
            color: $textcolor-eight;
            font-size: $font-13;
            white-space: nowrap;
            margin-left: 2px;
          }
          .css-qbdosj-Input {
            display: hidden;
          }
        }

        .css-1hb7zxy-IndicatorsContainer {
          padding: 0;
          margin: 0;

          .css-1u9des2-indicatorSeparator {
            margin-bottom: 0px;
            margin-top: 0px;
            display: none;
          }

          .css-15lsz6c-indicatorContainer,
          .css-1xc3v61-indicatorContainer {
            padding: 0;
          }
        }
      }

      .css-1nmdiq5-menu {
        font-size: $font-13;
        color: $textcolor-one;
        font-weight: 500;
        line-height: 1;
        padding: 0px 0px 0px 0px;
      }
    }
    .css-3iigni-container {
      align-items: center;
      position: relative;

      .css-16xfy0z-control {
        padding: 5px;
        width: 95%;
        margin: auto;
        border: 0px;
        border-radius: 5px;
        border-bottom: 1px solid $border-color;
        min-height: 10px;
        justify-content: flex-start;
        box-shadow: none;
        cursor: pointer;

        .css-1fdsijx-ValueContainer {
          height: 21px;
          margin-left: -6px;
          padding: 0px 8px;
          font-size: $font-14;

          .css-1jqq78o-placeholder {
            color: $textcolor-eight;
            font-size: $font-13;
            white-space: nowrap;
            margin-left: 2px;
          }
          .css-mohuvp-dummyInput-DummyInput {
            display: hidden;
          }
        }

        .css-1hb7zxy-IndicatorsContainer {
          padding: 0;
          margin: 0;

          .css-894a34-indicatorSeparator {
            margin-bottom: 0px;
            margin-top: 0px;
            display: none;
          }

          .css-1xc3v61-indicatorContainer {
            padding: 0;
          }
        }
      }

      .css-1nmdiq5-menu {
        font-size: $font-13;
        color: $textcolor-one;
        font-weight: 500;
        line-height: 1;
        padding: 0px 0px 0px 0px;
      }
    }
  }

  .hideAuctionLoad {
    display: hidden;
  }

  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $border-color;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: $five-color;
  }

  &::-webkit-scrollbar-track {
    background-color: $boxborder-color;
  }
  .moredetailsection {
    border-top: 1px solid $boxborder-color;
    display: flex;
    justify-content: space-between;

    .note {
      padding: 10px;
      width: 60%;
      p {
        margin: 0px;
      }
      textarea {
        min-height: 100px;
        min-width: 100%;
      }
    }
    .filessection {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      padding-right: 20px;

      img {
        height: 50px;
        width: 60px;
        margin: auto;
      }
      p {
        margin: 0px;
        font-size: $font-12;
      }
    }
  }
}

.addLoadForm {
  .loadEditBtn {
    cursor: pointer;
  }
}
