@import "../../../resource/styles/variables/variables.module.scss";

.TimeTracking_MainDiv {
  text-align: center;
  background-color: $background-color;
  padding: 60px 0px;
  margin-top: 20px;

  button {
    font-family: Prompt, sans-serif;
    font-size: $font-14;
    line-height: 24px;
    letter-spacing: 1.4px;
    padding: 15px 35px;
    background-color: $maintheme-color;
    color: $text-light;
    font-weight: 600;
  }
  p {
    font-family: Prompt, sans-serif;
    font-size: $font-36;
    line-height: 48px;
    letter-spacing: normal;
    font-weight: 700;
    padding-bottom: 30px;
    padding-top: 30px;
  }
  img {
    width: 80%;
    margin: auto;
  }
}
