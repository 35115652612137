@import "../../../../resource/styles/variables/variables.module.scss";

.raiseClaimModalDiv {
  border-top: 1px solid $boxborder-color;

  .eachContentDiv {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
    .contentMainHeading {
      margin: 10px 0px 5px 10px;
      font-size: $font-14;
      font-weight: bold;
      color: $primary-color;
    }
    .contentHeading {
      width: 40%;
      margin: 5px 0px 3px 10px;
      font-size: $font-14;
      font-weight: 500;
    }

    .totalText{
      font-weight: 500;
    }
  }
}
