@import "../../../../../resource/styles/variables/variables.module.scss";

.unloadingCompleteModal {
  border-top: 1px solid $boxborder-color;

  .fileUploadDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    p {
      font-size: $font-12;
      text-align: center;
    }
  }

  .eachContentDiv {
    width: 96%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    .contentMainHeading {
      margin: 0;
      font-size: $font-14;
      margin-left: 10px;
      margin-top: 10px;
      font-weight: bold;
      color: $primary-color;
    }
    .contentHeading {
      margin: 0;
      width: 55%;
      font-size: $font-14;
      margin-left: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: 500;
    }
    .contentHeading2 {
      margin: 0;
      width: 55%;
      font-size: $font-14;
      margin-left: 10px;
      margin-top: 7px;
      font-weight: 500;
    }
  }
  .totaldiv {
    justify-content: left;
    .contentText {
      font-weight: 500;
      margin-left: 10px;
    }
  }
}
