@import "../../../../resource/styles/variables/variables.module.scss";

.spNote {

  .noteHeading{
    margin: 0;
    font-weight: bold;
  }
  .noteText {
    margin: 0;
    margin-top : 5px;
    font-size: $font-14;
    font-family: $primary-font;
    line-height: 1;
  }
}
