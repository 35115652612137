@import "../../../../resource/styles/variables/variables.module.scss";

.showDocsDiv {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto, 1fr);
  gap: 20px;
  padding: 10px;
  border-top: 1px solid $boxborder-color;

  .docsDiv {
    .docImg {
      display: block;
      width: 90%;
      height: 150px;
      margin: auto;
    }
    .docTitle {
      margin: 0;
      font-size: $font-14;
      text-align: center;
      margin-top: 10px;
      .downloadIcon {
        color: $primary-color;
        cursor: pointer;
      }
    }
  }
}
