@import "../../../../../../resource/styles/variables/variables.module.scss";

.statusBoxEachBox {
  max-width: 27%;

  .heading {
    border-bottom: 1px solid $border-color;
    .headingText {
      color: $textcolor-one;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  .contentText {
    font-size: $font-14;
    margin: 0;
  }

  .belowbox {
    margin-top: 5px;
  }
}
