@import "../../../../resource/styles/variables/variables.module.scss";

.modal-body {
    border-top: 1px solid $boxborder-color;
    user-select: none;
    padding: 1rem;

    .input-box {
        display: flex;
        flex-direction: column;

        .message-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: small;

            p {
                text-align: right;
                width: 100%;
            }

            span {
                color: blue;
                text-decoration: underline;
                cursor: pointer;
                text-align: right;
                width: 100%;
            }
        }
    }

    input {
        outline: none;
        margin-top: 10px;
    }

    p,
    h4 {
        margin: 0;
    }

    .title-text {
        font-size: small;
        margin: .5rem 0;
    }

    .captcha-box {
        display: flex;
        width: 180px;
        height: 50px;
        gap: 20px;
        align-items: center;
        margin-top: .4rem;

        img {
            width: 140px;
            border: 1px solid #ccc;
        }
    }

    .verified-box {
        display: flex;
        gap: 10px;
        font-size: smaller;
        margin: .5rem 0;

        img {
            width: 150px;
            border-radius: 10px;
        }

        .aadhaar-details {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
    }

    .bold-label {
        font-weight: bold;

        span {
            font-weight: initial;
            font-size: 15px;
        }
    }

    .error-text {
        color: red;
        margin: 0;
        margin-top: .3rem;
        font-size: smaller;
    }
}