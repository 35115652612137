@import "../../../resource/styles/variables/variables.module.scss";

.spModalFooter {
  height: 35px;
  display: flex;
  background-color: $background-color;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 0px 10px;
  border-top: 1px solid $boxborder-color;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: 0px;
}

.myModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $bordershadow3-color;
  z-index: 200;

  .addloadform-content {
    background-color: $background-color;
    width: 75%;
    margin: auto;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      height: 25px;

      .flex {
        display: flex;
        gap: 10px;
        .headingText {
          font-weight: 500;
        }

        .closeIcon {
          font-size: $font-18;
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
  }
}

// *******Notification Modal*********
.notificationModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $bordershadow3-color;
  z-index: 200;
  justify-content: right;

  .notification-content {
    background-color: $background-color;
    width: 25%;
    height: 100vh;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      height: 35px;
      border-bottom: 1px solid $boxborder-color;

      .flex {
        display: flex;
        gap: 10px;
        .headingText {
          font-size: $font-24;
          font-size: $third-font;
          font-weight: bold;
        }

        .closeIcon {
          font-size: $font-22;
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
  }
  .spNotification-content {
    background-color: $background-color;
    width: 25%;
    height: 100vh;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      height: 35px;
      border-bottom: 1px solid $boxborder-color;

      .flex {
        display: flex;
        gap: 10px;
        .headingText {
          font-size: $font-24;
          font-size: $third-font;
          font-weight: bold;
        }

        .closeIcon {
          font-size: $font-22;
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
  }
}

// *******load Details Modal*********
.loadDetailModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $bordershadow3-color;
  z-index: 200;

  .loadDetailModal-content {
    background-color: $background-color;
    width: 60%;
    margin: auto;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      height: 25px;
      color: $textcolor-three;

      .flex {
        display: flex;
        gap: 10px;
        .headingText {
          font-weight: 500;
        }

        .closeIcon {
          font-size: $font-18;
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
  }
}

// **********Vehicle Committed Modal***********
.vehicleCommitedModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $bordershadow3-color;
  z-index: 200;

  .vehicleCommitedModal-content {
    background-color: $background-color;
    width: 50%;
    margin: auto;
    // min-height: 400px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      height: 25px;
      color: $textcolor-three;

      .flex {
        display: flex;
        gap: 10px;
        .headingText {
          font-weight: 500;
        }

        .closeIcon {
          font-size: $font-18;
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
  }
}

// **********Vehicle Cnf Modal***********
.VehicleCnfModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $bordershadow3-color;
  z-index: 200;

  .VehicleCnfModal-content {
    background-color: $background-color;
    width: 30%;
    margin: auto;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      height: 25px;

      .flex {
        display: flex;
        gap: 10px;
        .headingText {
          font-weight: 500;
        }

        .closeIcon {
          font-size: $font-18;
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
  }
}

// **********Confirm Modal***********
.cnfModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $bordershadow3-color;
  z-index: 200;

  .cnfModal-content {
    background-color: $background-color;
    width: 30%;
    margin: auto;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      height: 25px;

      .flex {
        display: flex;
        gap: 10px;
        .headingText {
          font-weight: 500;
        }

        .closeIcon {
          font-size: $font-18;
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
  }
}

// **********Awarded Accepted Modal***********
.awardedAcceptModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $bordershadow3-color;
  z-index: 200;

  .awardedAcceptModal-content {
    background-color: $background-color;
    width: 50%;
    margin: auto;
    // min-height: 400px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      height: 25px;

      .flex {
        display: flex;
        gap: 10px;
        .headingText {
          font-weight: 500;
        }

        .closeIcon {
          font-size: $font-18;
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
  }
}
