$default-color: #000000;
$primary-color: #df1e4a;
$secondary-color: #93c47d;
$secondary-colorlite: #d9ead3;
$three-color: #f9cb9c;
$three-colorlite: #fce5cd;
$four-color: #a4c2f4;
$four-colorlite: #c9daf8;
$five-color: #9e9d9d;
$five-colorlite: #ccc;
$six-color: rgb(220, 223, 223);
$seven-color: #f9fbfa;
$eight-color: rgb(238, 238, 238);
$nine-color: rgb(108, 108, 255);
$ten-color: orange;
$eleven-color: rgb(245, 61, 61);
$twelve-color: green;
$thirteen-color: #005eff;
$formLabelBackground-color: #faced8;

$mainboxshadow-color: rgba(0, 30, 43, 0.3) 0px 4px 10px -4px;
$boxborder-color: rgb(214, 214, 214);
$bordershadow-color: rgb(0 0 0 / 75%);
$bordershadow2-color: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
$bordershadow3-color: rgb(0 0 0 / 50%);
$bordershadow4-color: rgb(0 0 0 / 25%);
$bordershadow5-color: rgba(0, 0, 0, 0.35) 0px 5px 15px;
$bordershadow6-color: rgba(0, 0, 0, 0.16) 0px 1px 4px;

$background-color: #ffffff;
$background2-color: rgb(185, 130, 130);
$background3-color: rgba(233, 233, 233, 0.774);
$background4-color: rgb(248, 230, 230);
$background5-color: rgb(0, 167, 218);
$border-color: #6b7173;
$scrollbar-color: #bac5c9;

$btnDisabled-color: #9e9d9d;

$first-color: #2ca01c;

$textcolor-one: #000000;
$textcolor-two: #00684a;
$textcolor-three: #df1e4a;
$textcolor-four: #6b7173;
$textcolor-five: #d3d8da;
$textcolor-six: #c9daf8;
$textcolor-seven: #2ca01c;
$textcolor-eight: #9e9d9d;
$textcolor-nine: rgb(206, 134, 2);
$textcolor-ten: rgb(22, 188, 238);
$textcolor-eleven: rgb(18, 73, 223);
$textcolor-error: #ff0000;
$text-light: #ffffff;

$label-color-one: gray;

$line-color: #ebebeb;

// EaseMyTransport Website additional Variables

$maintheme-color: #8c0b0c;