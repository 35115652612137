@import "../../../resource/styles/variables/variables.module.scss";

.stepper-container {
    padding: 0 .5rem;

    .stepper-nav {
        display: flex;
        align-items: center;
        padding: .5rem 0;

        .step-nav-item {
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 0.875rem;
            border-radius: 5px;
            padding: .5rem;

            .step-nav-icon {
                background-color: #918f8f;
                border-radius: 50%;
                width: 1.5rem;
                height: 1.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 4px;
            }

            .step-nav-icon-active {
                @extend .step-nav-icon;
                background-color: $primary-color;
                color: #ffffff;
            }

            &:hover {
                background-color: rgb(234, 236, 238);
            }
        }

        .step-nav-item-active {
            @extend .step-nav-item;
            color: rgba(0, 0, 0, 0.87);
            font-weight: 500;
        }

        hr {
            flex: 1 1 auto;
            border: .5px solid #bdbdbd;
            margin: 0 .5rem;

            &:last-child {
                display: none;
            }
        }
    }

    .children{
        min-height: 320px;
    }

    .stepper-btn-container {
        display: flex;
        justify-content: space-between;
        margin: 1rem 0;
        user-select: none;

        button {
            border: none;
            outline: none;
            font-size: 0.875rem;
            line-height: 1.75;
            letter-spacing: 0.02857em;
            min-width: 64px;
            padding: 6px 8px;
            border-radius: 4px;
            margin-right: 0 10px;

            &:disabled {
                cursor: not-allowed;
                background-color: #e0e0e0;

                &:hover {
                    background-color: #e0e0e0;
                }
            }

            &:last-child {
                margin-left: 10px;
            }

            &:hover {
                background-color: $primary-color;
                color: #ffffff;
            }
        }
    }
}