@import "../../../../resource/styles/variables/variables.module.scss";

.Materialdetails {
  .headingDiv{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  h5 {
    margin: 0px;
    padding: 0px;
  }
  .viewMaterialImage {
    margin: 0;
    font-size: $font-14;
    color: $primary-color;
    cursor: pointer;
  }
  li {
    list-style-type: none;
    font-size: $font-14;
  }
  .odc {
    color: $textcolor-three;
    font-weight: 500;
  }
  .dg {
    color: $textcolor-three;
    font-weight: 500;
  }
  .vehicleDetailsHeading {
    display: flex;
    font-size: $font-14;
    margin: 0;
    flex-wrap: wrap;
  }
}
