@import "../../../resource/styles/variables/variables.module.scss";

header {
  height: 100px;
  padding: 0;
  margin-top: 1rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding-bottom: 10px;

  .imgDiv {
    width: 400px;
  }
}

nav a,
.Navbar_Button {
  padding: 0;
  margin: 0 1rem;
  text-decoration: none;
  font-size: $font-16;
  line-height: 20px;
  color: $maintheme-color;
  font-family: Prompt, sans-serif;
  font-weight: 550;
}

nav a:hover {
  text-decoration: underline;
}

nav {
  z-index: 10000;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: $maintheme-color;
  visibility: hidden;
  opacity: 0;
  font-size: $font-24;
  font-weight: 550;
}

header div,
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Navbar_Button {
  background: none;
  font: inherit;
  cursor: pointer;
  padding: 0.8rem 1rem;
  border: 2px solid $maintheme-color;
  font-weight: 600;
  color: $maintheme-color;
  width: 100%;
  border-radius: 10px;
}

.Navbar_Button:hover {
  border: 2px solid $maintheme-color;
  background-color: $maintheme-color;
  color: $text-light;
  font-weight: 600;
}

.Logo {
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 95%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 1.5rem;
    transition: 1s;
  }

  header .responsive_nav {
    transform: translateY(100vh);
    background-color: $text-light;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: $font-16;
  }
}