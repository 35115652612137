@import "../../../resource/styles/variables/variables.module.scss";

.headerRowTwoDiv {
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 0px 10px;
  box-shadow: $mainboxshadow-color;
  height: 40px;

  
}
