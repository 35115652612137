@import "../../../../resource/styles/variables/variables.module.scss";

.auctiontimebody {
  border-radius: 5px;
  padding-right: 10px;
  h5 {
    margin: 0px;
    padding: 0px;
    text-align: center;
  }
  li {
    list-style-type: none;
    font-size: $font-14;
    text-align: center;
  }
}
