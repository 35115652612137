@import "../../../resource/styles/variables/variables.module.scss";

.greenRankText {
    margin: 0;
    font-weight: 500;
    font-size: $font-18;
    color: $textcolor-two;
  }
  .redRankText {
    margin: 0;
    font-weight: 500;
    font-size: $font-18;
    color: $textcolor-error;
  }