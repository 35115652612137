@import "../../../../../resource/styles/variables/variables.module.scss";

.processAdvanceDiv {
  border-top: 1px solid $boxborder-color;

  .eachProcessAdvanceDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;

    .processAdvanceHeading {
      margin: 0;
      width: 40%;
      font-size: $font-14;
      font-weight: 500;
    }

    .processAdvanceText {
      margin: 0;
      font-size: $font-14;
    }
  }
  .insufficientBalText {
    text-align: right;
    margin: 0;
    margin-right: 10px;
    font-size: $font-14;
    color: $textcolor-error;
  }

  .processAdvanceCnfMessage {
    font-size: $font-15;
    font-weight: 500;
    margin: 10px;
  }
}
