@import "../../../../resource/styles/variables/variables.module.scss";

.addBalanceModalDiv {
  border-top: 1px solid $boxborder-color;
  margin-left: 5px;
  // margin-right: 5px;

  .forScroll {
    height: 400px;
    overflow-y: scroll;
    scrollbar-width: 7px;
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $border-color;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: $five-color;
    }
    &::-webkit-scrollbar-track {
      background-color: $boxborder-color;
    }

    .eachContentDiv {
      display: flex;
      justify-content: left;
      align-items: center;
      gap: 10px;
      padding-left: 10px;

      .contentHeading {
        margin: 0;
        width: 45%;
        font-size: $font-14;
        font-weight: 500;
      }
      .labelHeading {
        // border: 1px solid;
        margin: 0;
        width: 85%;
        font-size: $font-14;
        font-weight: 500;
      }
      .contentText {
        margin: 0;
        font-size: $font-14;
      }
    }

    .bankDetailsDiv {
      border: 1px solid $border-color;
      border-radius: 5px;
      padding: 3px 0px;
      margin-right: 5px;
    }

    .orDiv {
      padding: 2px;
      .orText {
        margin: 0;
        text-align: center;
        font-size: $font-14;
        font-weight: 500;
      }
    }

    .fileUploadDiv {
      margin-top: 5px;
      margin-bottom: 10px;
    }
  }
  .guideDiv {
    .guideText {
      margin: 0;
      font-size: $font-14;
      font-weight: 500;
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 10px;
    }
  }
  .modalMainHeading {
    margin: 0;
    font-weight: 500;
    color: $primary-color;
    margin-left: 10px;
    font-size: $font-15;
  }
}
