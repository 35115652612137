@import "../../../resource/styles/variables/variables.module.scss";
.csLabel {
  margin: 0;
  font-size: $font-11;
  color: $label-color-one;
  font-family: $third-font;
  font-weight: bold;
  padding-left: 10px;
}
.customSelectBox {
  .css-b62m3t-container {
    align-items: center;
    position: relative;

    .css-t3ipsp-control,
    .css-13cymwt-control {
      padding: 0;
      width: 100%;
      margin: auto;
      border: 0px;
      border-radius: 0px;
      border-bottom: 1px solid $border-color;
      min-height: 10px;
      justify-content: flex-start;
      box-shadow: none;
      cursor: pointer;

      .css-1fdsijx-ValueContainer {
        height: 21px;
        margin-left: -6px;
        padding: 0px 8px;
        font-size: $font-14;

        .css-1jqq78o-placeholder {
          color: $textcolor-eight;
          font-size: $font-13;
          white-space: nowrap;
          margin-left: 2px;
          // position: absolute;
          // bottom: 10px;
          // transition: all 0.2s ease-in-out;
        }
        .css-qbdosj-Input {
          display: hidden;
        }
      }

      .css-1hb7zxy-IndicatorsContainer {
        padding: 0;
        margin: 0;

        .css-1u9des2-indicatorSeparator {
          margin-bottom: 0px;
          margin-top: 0px;
          display: none;
        }

        .css-15lsz6c-indicatorContainer,
        .css-1xc3v61-indicatorContainer {
          padding: 0;
        }
      }
    }

    .css-1nmdiq5-menu {
      // height: 500px;
      // width: 220px;
      font-size: $font-13;
      color: $textcolor-one;
      font-weight: 500;
      line-height: 1;
      padding: 0px 0px 0px 0px;
      // border: 1px solid black;
      // .css-1nmdiq5-menu-list {
      //   background-color:  red;
      //   font-size: $font-16;
      // }
      // .css-1nmdiq5-option--is-selected {
      //   background-color:  red;
      //   font-size: $font-16;
      //   color: green;
      // }
      // .css-1nmdiq5-option--is-focused {
      //   background-color:  red;
      //   font-size: $font-16;
      // }
      // .css-1nmdiq5-control {
      //   background-color:  red;
      //   font-size: $font-16;
      // }
    }
  }
}

.errorText {
  margin: 0;
  font-size: $font-12;
  color: $textcolor-three;
  white-space: nowrap;
}

// css-1nmdiq5-menu-list
// css-1nmdiq5-option--is-selected
// css-1nmdiq5-option--is-focused
// css-1nmdiq5-control

// ****************Weight Categorydropdown menu width fixed ************
.customSelectBox2 {
  .css-b62m3t-container {
    align-items: center;
    position: relative;

    .css-t3ipsp-control,
    .css-13cymwt-control {
      padding: 0;
      width: 100%;
      margin: auto;
      border: 0px;
      border-radius: 0px;
      border-bottom: 1px solid $border-color;
      min-height: 10px;
      justify-content: flex-start;
      box-shadow: none;
      cursor: pointer;

      .css-1fdsijx-ValueContainer {
        height: 21px;
        margin-left: -6px;
        padding: 0px 8px;
        font-size: $font-14;

        .css-1jqq78o-placeholder {
          color: $textcolor-eight;
          font-size: $font-13;
          white-space: nowrap;
          margin-left: 2px;
          // position: absolute;
          // bottom: 10px;
          // transition: all 0.2s ease-in-out;
        }
        .css-qbdosj-Input {
          display: hidden;
        }
      }

      .css-1hb7zxy-IndicatorsContainer {
        padding: 0;
        margin: 0;

        .css-1u9des2-indicatorSeparator {
          margin-bottom: 0px;
          margin-top: 0px;
          display: none;
        }

        .css-15lsz6c-indicatorContainer,
        .css-1xc3v61-indicatorContainer {
          padding: 0;
        }
      }
    }

    .css-1nmdiq5-menu {
      // height: 500px;
      width: 220px;
      font-size: $font-13;
      color: $textcolor-one;
      font-weight: 500;
      line-height: 1;
      padding: 0px 0px 0px 0px;
      // border: 1px solid black;
      // .css-1nmdiq5-menu-list {
      //   background-color:  red;
      //   font-size: $font-16;
      // }
      // .css-1nmdiq5-option--is-selected {
      //   background-color:  red;
      //   font-size: $font-16;
      //   color: green;
      // }
      // .css-1nmdiq5-option--is-focused {
      //   background-color:  red;
      //   font-size: $font-16;
      // }
      // .css-1nmdiq5-control {
      //   background-color:  red;
      //   font-size: $font-16;
      // }
    }
  }
}
