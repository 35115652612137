@import "../../../resource/styles/variables/variables.module.scss";
.TopNavbarparent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  height: 40px;
  border-bottom: 1px solid $line-color;

  .TopNavbar {
    display: flex;
    gap: 10px;
    font-family: $primary-font;
    align-items: center;

    .navonetitle {
      margin: unset;
      font-family: $primary-font;
      font-size: $font-12;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: $textcolor-three;
    }

    .line {
      border-right: 1px solid $boxborder-color;
      height: 30px;
    }

    .filtericon {
      position: relative;
      cursor: pointer;
    }
    .filterBox {
      width: 200px;
      border: none;
      position: absolute;
      z-index: 10;
      top: 162px;
      left: 4;
      line-height: 1;
      margin-top: 0px;
      // margin-left: 4px;
    }

    .displayhidden {
      display: none;
    }

    .activePage {
      text-decoration: none;

      .links {
        height: 25px;
        border-radius: 12px;
        cursor: pointer;
        border: 1px solid $primary-color;
        background-color: $primary-color;
        text-decoration: none;
        font-weight: bold;
        p {
          color: $text-light;
          margin: 0px;
          line-height: 1;
          padding: 0px 10px;
          font-size: $font-12;
        }
      }
    }

    .routelink {
      text-decoration: none;

      .links {
        height: 25px;
        border-radius: 12px;
        border: 1px solid $boxborder-color;
        cursor: pointer;
        background-color: none;
        color: $textcolor-one;
        text-decoration: none;

        &:hover {
          border: 1px solid $primary-color;
          color: $textcolor-three;
        }
        p {
          margin: 0px;
          line-height: 1;
          padding: 0px 10px;
          font-size: $font-12;
        }
      }

      img {
        height: 20px;
      }

      .filter {
        display: flex;
        justify-content: end;
        width: auto;
        cursor: pointer;
      }
    }
  }

  .topNavbarSectionDiv {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 10px;
  }
}
