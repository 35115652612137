@import "../../../resource/styles/variables/variables.module.scss";

.SupportSection_MainDiv {
  width: 100%;
  
  text-align: center;
  position: relative;
  margin-top: 50px;

  .text_div {
    width: 100%;
    margin: auto;
    position: absolute;
    top: 50%;
  }

  img {
    width: 100%;
  }
}

.text_div p:nth-child(1) {
  font-family: sans-serif;
  font-size: $font-48;
  font-weight: 700;
  line-height: 57.6px;
  text-align: center;
  color: $text-light;
  padding-bottom: 50px;
}

.text_div p:nth-child(2) {
  font-family: sans-serif;
  font-size: $font-20;
  line-height: 20px;
  text-align: center;
  color: $text-light;
  font-weight: 600;
}

.button_div {
  width: 35%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.ContactUs_button {
  background-color: $maintheme-color;
  font-size: $font-14;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1.4px;
  padding: 15px 30px;
  color: $text-light;
}

.ContactUs_button:hover {
  background-color: $text-light;
  color: $default-color;
}

.GiveUsRing_button {
  background-color: $text-light;
  font-size: $font-14;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1.4px;
  padding: 15px 30px;
  color: $default-color;
}

.GiveUsRing_button:hover {
  background-color: $maintheme-color;
  color: $text-light;
}

@media only screen and (max-width: 1200px) {
  .SupportSection_MainDiv {
    .text_div {
      color: $maintheme-color;
      position: absolute;
      top: 0%;

      p {
        font-size: medium;
      }
    }
  }

  .ContactUs_button {
    padding: 10px 10px;
    font-weight: 200;
  }

  .GiveUsRing_button {
    padding: 10px 10px;
    font-weight: 200;
  }

  .button_div {
    width: 80%;
    margin-top: 20px;
  }

  .text_div p:nth-child(1) {
    padding-bottom: 20px;
  }
}
