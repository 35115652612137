@import "../../../resource/styles/variables/variables.module.scss";

.beforeAuctionText{
 margin: 0;
 font-size: $font-14;
 font-weight: 500;
 text-align: center;
}
.timerDiv {
  h5 {
    margin: 0;
    text-align: center;
  }
  .timer {
    font-size: $font-20;
    font-weight: bold;
    margin: 0;
    color: $textcolor-two;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
  }
  .aboutEnd {
    font-size: $font-20;
    font-weight: bold;
    margin: 0;
    color: $textcolor-three;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
  }
  .danger {
    font-size: $font-16;
    font-weight: bold;
    margin: 0;
    color: $textcolor-three;
    text-align: center;
  }
}
