@import "../../../../../../resource/styles/variables/variables.module.scss";

.SpContainer {
  .detailssection {
    height: auto;
    width: 25%;

    .loadDetailsDiv {
      margin-top: -20px;
      display: flex;
      gap: 5px;

      .loadDetailsTextOne {
        margin: 0;
        font-size: $font-14;
        font-weight: bold;
      }
      .loadDetailsText {
        margin: 0;
        font-size: $font-14;
        font-weight: bold;
        text-decoration: underline;
        color: $textcolor-three;
        cursor: pointer;
      }
    }
  }

  .auctionquoteDiv {
    position: relative;

    .selectionBtnDiv {
      gap: 10px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  .listodshortlist {
    display: none;
    grid-template-columns: repeat(4, 1fr);

    @media screen and (max-width: $screen-sm) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .boxcenter {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .awardedStatusText {
    margin: 0;
    color: $textcolor-two;
    font-weight: bold;
    gap: 5px;

    .rejectedStatusText {
      margin: 0;
      color: $textcolor-error;
    }
  }
  .pendingStatusText {
    margin: 0;
    color: $textcolor-nine;
    font-weight: bold;
  }
}
