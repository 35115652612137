@import "../../../../resource/styles/variables/variables.module.scss";


.modal-container {
    box-sizing: border-box;
    margin: 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    border-top-width: 7px;
    border-top-color: #f5436d;
}