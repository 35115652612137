.boxcenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.toastContainer {
  .Toastify__toast {
    width: 90%;
    min-height: 50px;
  }
}

// *{
//   padding: 0;
//   margin: 0;
//   box-sizing: border-box;
// }
