@import "../../../../resource/styles/variables/variables.module.scss";

.spBodyid {
  align-items: center;
  height: 100%;
  display: flex;
  gap: 5px;

  .newLoadDiv {
    padding: 1px 5px;
    background-color: $twelve-color;
    border-radius: 5px;

    .newLoadText {
      margin: 0;
      font-size: $font-12;
      color: $text-light;
      font-weight: 500;
    }
    animation: blinkAnimation 1.5s infinite;

    @keyframes blinkAnimation {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
  .idimg {
    height: 12px;
    display: none;
  }
  .p {
    color: $primary-color;
    margin: 0px 0px 3px 0px;
    padding: 0px;
    font-weight: bold;
    font-size: $font-16;
  }
}
