@import "../../../../../resource/styles/variables/variables.module.scss";

th{
    font-family: $third-font;
    font-size: $font-14;
    min-width: 100px;
}
.claimTh{
    padding: 1px;
}
.alignleft{
    text-align: left;
}
.alignright{
    text-align: right;
}