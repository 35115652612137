@import "../../../../resource/styles/variables/variables.module.scss";

.acceptBidsDiv {
  .targetPrice {
    margin: 0;
    margin-top: 10px;
    font-weight: 500;
    font-size: $font-15;
    text-align: center;
    padding: 12px 9px;
    background-color: $background3-color;
    box-shadow: $mainboxshadow-color;
    border-radius: 10px;
  }

  .acceptBtnDiv {
    margin: auto;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}
