@import "../../../resource/styles/variables/variables.module.scss";


.Card{
    width: 100%;
    margin: auto;
    border-radius: 30px;
    background-color: $background-color;
    border: 1px solid $boxborder-color;
    margin-top: 20px ;
    box-shadow: $mainboxshadow-color;

}