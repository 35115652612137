@import "../../../../../../resource/styles/variables/variables.module.scss";

.userManagementPage {
  width: 100%;
  padding: 5px;

  .userManagementHeading {
    margin: 10px;
    font-size: $h3;
    color: $textcolor-three;
  }

  .userManagementContent {
    border: 1px solid $boxborder-color;
    border-radius: 10px;
    padding: 20px 10px 20px 10px;


      .contentHeading{
        margin-top: 5px;
        font-size : $font-20;
        font-weight: 500;
      }


  }
}
