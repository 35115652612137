@import "../../../resource/styles/variables/variables.module.scss";

.currentTasktable {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin-top: 10px;

  .eachClaimDiv {
    .acceptedClaimText {
      margin: 0;
      font-weight: bold;
      color: $textcolor-two;
    }
    .rejectedClaimText {
      margin: 0;
      font-weight: bold;
      color: $textcolor-error;
    }
  }

  .claimsHeading {
    margin: 0;
    font-size: $font-14;
    font-weight: 500;
    // text-decoration: underline;
    margin-left: 10px;
    background-color: $background4-color;
    // color: $textcolor-four;
    padding-left: 10px;
  }
  .noTableMessage {
    margin: 0;
    font-size: $font-14;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-top: 10px;
  }
}

.recentClaimCurrentTaskTable {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  padding-right: 10px;

  .eachClaimDiv {
    width: 48%;
    .acceptedClaimText {
      margin: 0;
      font-weight: bold;
      color: $textcolor-two;
    }
    .rejectedClaimText {
      margin: 0;
      font-weight: bold;
      color: $textcolor-error;
    }
  }

  .claimsHeading {
    margin: 0;
    font-size: $font-14;
    font-weight: 500;
    // text-decoration: underline;
    margin-left: 10px;
    background-color: $background4-color;
    // color: $textcolor-four;
    padding-left: 10px;
  }
  .noTableMessage {
    margin: 0;
    font-size: $font-14;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-top: 10px;
  }
}
