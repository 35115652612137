@import "../../../resource/styles/variables/variables.module.scss";

.Inputdropdown {
  background-color: $background-color;
  box-shadow: 0 2px 4px -1px $bordershadow-color;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  max-height: 250px;
  position: relative;
  width: auto;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 5px 0px 5px 0px;
  display: block;
  border-bottom: none;
  cursor: pointer;
  font-weight: 500;
  scrollbar-width: thin;
  scrollbar-color: transparent;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: $background-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-color;
    border-radius: 4px;
  }

  &::-ms-scrollbar {
    width: 8px;
  }

  &::-ms-scrollbar-track {
    background-color: $background-color;
  }

  &::-ms-scrollbar-thumb {
    background-color: $primary-color;
    border-radius: 4px;
  }

  .selectDropdown {
    font-size: 12px;
    width: 100%;
    padding: 4px;

    &:hover {
      background-color: $primary-color;
      color: $text-light;
      border-radius: 5px;
    }
  }

  .forHover {
    font-size: 12px;
    padding: 4px;
    width: 100%;
    background-color: $primary-color;
    color: $text-light;
    border-radius: 5px;
  }

  .mad {
    display: block;
    position: relative;
    white-space: normal;
    text-overflow: ellipsis;
    overflow: visible !important;
    line-height: 22px;
    height: auto;
    padding: 0 8px;
    text-align: left;
    text-decoration: none;
    font-size: $font-13;
    max-width: auto !important;
    cursor: pointer;
    outline: 0;
    flex-direction: row;
    box-sizing: border-box;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
  }
}
