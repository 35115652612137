@import "../../../resource/styles/variables/variables.module.scss";

.paginationDiv {
  gap: 5px;
  padding: 5px;
  color: $text-light;
  font-size: $font-13;
  background-color: $primary-color;

  .goto {
    gap: 5px;

    input {
      width: 40px;
      outline: none !important;
    }
  }

  select {
    outline: none !important;
  }
}
