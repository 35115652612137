@import "../../../resource/styles/variables/variables.module.scss";

.LandingPage_TopNavBar_mainDiv {
  height: 50px;
  width: 100%;
  background-color: $maintheme-color;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: $text-light;
  line-height: 24px;
  letter-spacing: normal;
  font-weight: 600;

  .TopNavBar_ContactDiv {
    width: 60%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 5%;

    .icon {
      color: $text-light;
    }
  }

  .TopNavBar_ContactDiv div {
    display: flex;
    align-items: center;
  }

  .TopNavBar_ContactDiv p {
    padding-left: 10px;
  }

  .SocialMediaLink {
    width: 10%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-right: 5%;

    .socialMediaIcons {
      color: $text-light;
      font-size: $font-22;
      cursor: pointer;
     

      &:hover {
        font-size: $font-24;
      }
    }
  }
}

@media (max-width: 767px) {
  .LandingPage_TopNavBar_mainDiv {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  /* CSS rules for medium screens */
  .LandingPage_TopNavBar_mainDiv {
    display: none;
  }
}
