@import "../../../../../../resource/styles/variables/variables.module.scss";

.vehicleManagementPage {
  width: 100%;
  padding: 5px;

  .vehicleManagementHeading {
    margin: 10px;
    font-size: $h3;
    color: $textcolor-three;
  }

  .vehicleManagementContent {
    border: 1px solid $boxborder-color;
    border-radius: 10px;
    padding: 20px 10px 20px 10px;

    .contentHeading {
      margin-top: 5px;
      font-size: $font-20;
      font-weight: 500;

      
    }
  }
}
