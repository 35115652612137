@import "../../../resource/styles/variables/variables.module.scss";

.btnDivision {
  margin: 0;
  padding: 0;
  margin-bottom: -8px;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 5px;
}

// .greenText{
//   margin: 0;
//   padding: 1px;
//   color: $textcolor-two;
// }

// .redText{
//   margin: 0;
//   padding: 1px;
//   color:$textcolor-error
// }
