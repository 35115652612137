@import "../../../../resource/styles/variables/variables.module.scss";

.awardedAcceptedModal {
  border-top: 1px solid $boxborder-color;
  padding: 5px;
  
  .lastDiv{
    margin-bottom: 10px;
  }

  .eachModalContentDiv {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
    margin-top: 5px;

    .customSelectBox{
      width: 40%;
      .react-select{
        width: 100%;
      }
    }

    .modalText {
      margin: 0;
      width: 35%;
      font-size: $font-14;
      font-weight: 500;
    }

    
  }
}
