@import "../../../../resource/./styles/variables/variables.module.scss";

.Addloadsection {
  .headingaddloadsection {
    background-color: $background4-color;
    font-size: $font-14;
    font-weight: 500;
    padding: 2px 5px 2px 5px;
    margin-top: 6px;
  }
  .contentAddload {
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 5px;
    padding: 5px 0px;
    // grid-auto-flow: column;

    @media screen and (max-width: $screen-xs) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .contentAddload1 {
    width: 98%;
    margin: auto;
    display: grid;
    grid-template-columns: 0.9fr 5fr;
    gap: 15px;
    padding: 5px 0px;
    margin-left: 10px;

    @media screen and (max-width: $screen-xs) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
