@import "../../../../../../resource/styles/variables/variables.module.scss";

table {
  border-collapse: collapse;

  thead {
    tr {
      margin-bottom: 30px;

      th {
        padding: 10px;
        font-size: $font-14;
        // background-color: $background3-color;
      }
      .headactable {
        padding: 10px;
        font-size: $font-14;
        background-color: $background3-color;
      }
      .descdiv {
        display: flex;
        gap: 30px;
        .desctext {
          margin-top: 6px;
        }
        .searchContainer {
          position: relative;
        }

        .searchIcon {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          color: $five-color;
          cursor: pointer;
        }

        .searchInput {
          width: 100%;
          padding: 16px 32px 12px 12px;
          font-size: 14px;
          border-bottom: 1px solid $default-color;
        }

        .searchInput:focus {
          outline: none;
        }
      }
    }
  }
  tbody {
    border: none;

    .oddRow {
      &:nth-child(even) {
        background-color: $background3-color;
      }

      td {
        padding: 10px;
        font-size: $font-13;

        .forLink {
          // color: $textcolor-three;
          font-size: $font-14;
          text-decoration: underline;
          cursor: pointer;
        }
        .creditAmount {
          color: $textcolor-two;
        }
        .debitAmount {
          color: $textcolor-error;
        }
      }
    }
  }
}
