@import "../../../../../resource/styles/variables/variables.module.scss";

th{
    font-family: $third-font;
    font-size: $font-14;
}
.alignleft{
    text-align: left;
}
.alignright{
    text-align: right;
}