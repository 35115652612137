@import "../../../../resource/styles/variables/variables.module.scss";

.addVehicleModal {
  border-top: 1px solid $boxborder-color;

  .addVehicleModalContentDiv {
    justify-content: space-between;
    gap: 10px;
    padding: 10px 5px;

    .addVehicleModalHeaderDiv {
      width: 40%;

      .addVehicleModalHeader {
        margin: 5px;
        font-size: $font-15;
        color: $textcolor-three;
        font-weight: 500;
      }
    }
    input {
      display: block;
    }
  }
}
