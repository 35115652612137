$primary-font: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
$secondary-font: "PT Serif", serif;
$third-font: "Euclid Circular A", Akzidenz, "Helvetica Neue", Helvetica, Arial,
  sans-serif;

//headings
$h1: 26px;
$h2: 24px;
$h3: 22px;
$h4: 20px;
$h5: 18px;
$h6: 16px;

//standard font size
$font-1: 1px;
$font-11: 11px;
$font-12: 12px;
$font-14: 14px;
$font-13: 13px;
$font-15: 15px;
$font-16: 16px;
$font-18: 18px;
$font-20: 20px;
$font-22: 22px;
$font-24: 24px;
$font-26: 26px;
$font-28: 28px;
$font-30: 30px;
$font-32: 32px;
$font-34: 34px;
$font-36: 36px;
$font-48: 48px;

