@import "../../../../src/resource/styles/variables/variables.module.scss";

.buttonDiv {
  display: flex;
  gap: 5px;
}

.addload {
  width: 100px;
  height: 28px;
  background-color: $three-color;
  border: 1px solid $default-color;
  border-radius: 5px;
}

.btn1 {
  width: 100%;
  padding: 7px 0px;
  color: $text-light;
  font-weight: bold;
  background-color: $first-color;
  border: 1px solid $background-color;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}

.btn1Disalbe {
  width: 100%;
  padding: 7px 0px;
  color: $text-light;
  font-weight: bold;
  background-color: $btnDisabled-color;
  border: 1px solid $btnDisabled-color;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: not-allowed;
}

.btn2 {
  width: 100%;
  padding: 7px 0px;
  color: $textcolor-seven;
  font-weight: bold;
  background-color: $background-color;
  border: 1px solid $first-color;
  border-radius: 5px;
  cursor: pointer;
}

.selectionbtn {
  border: none;
  padding: 5px 15px;
  color: $textcolor-six;
  background-color: $background-color;
  border-bottom: 1px solid $four-colorlite;
  border-radius: 3px;
  cursor: pointer;
}

.selected {
  padding: 5px 15px;
  border: none;
  color: $textcolor-seven;
  background-color: $background-color;
  border-bottom: 2px solid $first-color;
  font-weight: 600;
  border-radius: 3px;
  cursor: pointer;
}

.acceptBtn {
  color: $text-light;
  border: 1px solid $primary-color;
  background-color: $primary-color;
  border-radius: 5px;
  padding: 5px 14px;
  margin-top: 3px;
  font-weight: 600;
  cursor: pointer;
}

.loadingAcceptBtn {
  color: $text-light;
  border: 1px solid $primary-color;
  background-color: $primary-color;
  border-radius: 5px;
  padding: 4px 30px;
  margin-top: 3px;
  font-weight: 600;
  cursor: pointer;
}

.acceptBtndisabled {
  color: $text-light;
  border: 1px solid $btnDisabled-color;
  background-color: $btnDisabled-color;
  border-radius: 5px;
  padding: 5px 14px 5px 14px;
  margin-top: 3px;
  font-weight: 600;
  cursor: not-allowed;
}

.rejectBtn {
  color: $primary-color;
  border: 1px solid $primary-color;
  border-radius: 5px;
  padding: 5px 14px 5px 14px;
  margin-top: 3px;
  font-weight: 600;
  cursor: pointer;
}

.taskBtn1 {
  color: $text-light;
  border: 1px solid $first-color;
  background-color: $first-color;
  padding: 5px 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.taskBtn2 {
  color: $text-light;
  border: 1px solid $first-color;
  background-color: $first-color;
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.taskBtn3 {
  color: $text-light;
  border: 1px solid $first-color;
  background-color: $primary-color;
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.auctionLoad {
  color: $text-light;
  border: 1px solid $nine-color;
  background-color: $nine-color;
  padding: 3px 5px 3px 5px;
  font-weight: 700;
  border-radius: 5px;
}

.openLoad {
  color: $text-light;
  border: 1px solid $twelve-color;
  background-color: $twelve-color;
  padding: 3px 5px 3px 5px;
  font-weight: 700;
  border-radius: 5px;
}

.fixedLoad {
  color: $text-light;
  border: 1px solid $thirteen-color;
  background-color: $thirteen-color;
  padding: 3px 5px 3px 5px;
  font-weight: 700;
  border-radius: 5px;
}

.fpo_text {
  color: $text-light;
  border: 1px solid $nine-color;
  background-color: $nine-color;
  padding: 3px 5px 3px 5px;
  font-weight: 700;
  border-radius: 5px;
}

.pv_text {
  color: $text-light;
  background-color: $ten-color;
  border: 1px solid $ten-color;
  padding: 3px 5px 3px 5px;
  font-weight: 700;
  border-radius: 5px;
}

.pmt_text {
  color: $text-light;
  background-color: $eleven-color;
  border: 1px solid $eleven-color;
  padding: 3px 5px 3px 5px;
  font-weight: 700;
  border-radius: 5px;
}

.cnfBtn {
  color: $text-light;
  border: 1px solid $first-color;
  background-color: $first-color;
  margin-bottom: 10px;
  padding: 2px 5px;
  border-radius: 5px;
  cursor: pointer;
}

.cnfDisabledBtn {
  color: $text-light;
  border: 1px solid $btnDisabled-color;
  background-color: $btnDisabled-color;
  margin-bottom: 10px;
  padding: 2px 5px;
  border-radius: 5px;
  cursor: pointer;
  cursor: not-allowed !important;
}

.acptBtn {
  color: $text-light;
  border: 1px solid $first-color;
  background-color: $first-color;
  margin-bottom: 5px;
  padding: 2px 5px;
  border-radius: 5px;
  cursor: pointer;
}

.rejBtn {
  color: $text-light;
  border: 1px solid $eleven-color;
  background-color: $eleven-color;
  margin-bottom: 5px;
  padding: 2px 5px;
  border-radius: 5px;
  cursor: pointer;
}

.addLoadFormBtn {
  color: $text-light;
  border: 1px solid $primary-color;
  background-color: $primary-color;
  padding: 8px 10px 8px 10px;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
}

.disableAddLoadFormBtn {
  color: $text-light;
  border: 1px solid $primary-color;
  background-color: $primary-color;
  padding: 8px 10px 8px 10px;
  font-weight: 700;
  border-radius: 5px;
  cursor: not-allowed;
}

.disabledBtn {
  cursor: not-allowed;
  color: $text-light;
  // border: 1px solid $primary-color;
  background-color: grey;
  padding: 8px 10px 8px 10px;
  font-weight: 700;
  border-radius: 5px;
  // cursor: pointer;
}

.disabledBtnSave {
  color: $text-light;
  // border: 1px solid $primary-color;
  background-color: grey;
  padding: 7px 10px 7px 10px;
  font-weight: 700;
  border-radius: 5px;
  cursor: not-allowed;
}

.disabledBtnBank {
  color: $text-light;
  background-color: grey;
  padding: 4px 8px 4px 8px;
  font-weight: 700;
  border-radius: 5px;
  margin-top: 4px;
  cursor: not-allowed;
}

.bidBtnActive {
  color: $text-light;
  border: 1px solid $primary-color;
  background-color: $primary-color;
  padding: 8px 10px 8px 10px;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
}

.btnDisabled {
  color: $text-light;
  border: 1px solid $btnDisabled-color;
  background-color: $btnDisabled-color;
  padding: 8px 10px 8px 10px;
  font-weight: 700;
  border-radius: 5px;
  cursor: not-allowed !important;
}

.modalbutton {
  color: $text-light;
  border: 1px solid $primary-color;
  background-color: $primary-color;
  border-radius: 5px;
  padding: 5px 14px 5px 14px;
  cursor: pointer;
}

.modalbuttonDisable {
  color: $text-light;
  border: 1px solid $btnDisabled-color;
  background-color: $btnDisabled-color;
  border-radius: 5px;
  padding: 5px 14px 5px 14px;
  cursor: pointer;
}

.walletbtn {
  width: 100%;
  color: $text-light;
  border: 1px solid $primary-color;
  background-color: $primary-color;
  border-radius: 5px;
  padding: 5px 14px 5px 14px;
  margin-top: 3px;
  font-weight: 600;
  cursor: pointer;
}

.walletbtnTwo {
  width: 100%;
  color: $primary-color;
  border: 1px solid $primary-color;
  border-radius: 5px;
  padding: 5px 14px 5px 14px;
  margin-top: 3px;
  font-weight: 600;
  cursor: pointer;
}

.LandingPage_Button {
  background-color: #ffffff;
  height: 56px;
  width: 152px;
  border-color: #8c0b0c;
  border-width: 1px;
  padding: 23px 13px;
  color: #000000; // changed this to black for visibility
  letter-spacing: 1.4px;
  line-height: 24px;
  font-size: 14px;
  text-align: center;

  &:hover {
    background-color: #8c0b0c;
    color: #ffffff; // this makes the text white when button is hovered
  }
}
