@import "../../../../resource/styles/variables/variables.module.scss";

.addUserModalDiv {
  padding: 10px 10px 0px 10px;
  border-top: 1px solid $boxborder-color;

  .addUserModalContentDiv {
    justify-content: left;
    gap: 10px;
    .addUserModalHeaderDiv {
      width: 40%;

      .addUserModalHeader {
        margin: 5px;
        font-size: $font-15;
        color: $textcolor-three;
        font-weight: 500;
      }
    }
    input {
      display: block;
    }
  }

  .handleNotificationBox {
    display: flex;
    justify-content: left;
    gap: 20px;
    margin-top: 10px;

    .emailNotification {
      .notificationHeading {
        margin: 0px;
        font-size: $font-14;
        color: $textcolor-four;
      }
      .contentText {
        margin: 10px;
        font-size: $font-14;
        display: flex;
        // align-items: center;
        gap: 5px;
        color: $textcolor-four;

        label {
          display: flex;
          align-items: flex-start;

          input {
            width: auto;
          }
        }
      }
    }

    .SMSNotification {
      .notificationHeading {
        margin: 0px;
        font-size: $font-14;
        color: $textcolor-four;
      }
      .contentText {
        margin: 10px;
        font-size: $font-14;
        display: flex;
        align-items: center;
        gap: 5px;
        color: $textcolor-four;

        label {
          display: flex;
          justify-content: flex-start;

          input {
            width: auto;
          }
        }
      }
    }
  }
}
