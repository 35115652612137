@import "../../../resource/styles/variables/variables.module.scss";

.Footer_Main_div {
  // background-color: #F9F7F5;
  display: flex;
  justify-content: space-around;
  width: 95%;
  margin: auto;

  .infoDiv {
    width: 25%;
    padding-top: 70px;

    .contentsDiv {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    img {
      width: 50%;
    }

    div {
      padding-top: 10px;
      display: flex;
      align-items: center;
    }

    p {
      margin: 0;
      padding: 0;
      font-family: sans-serif;
      font-size: $font-15;
      line-height: 1.5;
      text-align: justify;
      color: $textcolor-four;
      cursor: pointer;
    }

    .SocialMediaLink {
      width: 30%;
      display: flex;
      justify-content: left;
      align-items: center;
      gap: 10px;

      .socialMediaIcons {
        color: $maintheme-color;
        font-size: $font-24;
      }

      .socialMediaIcons:hover {
        cursor: pointer;
        color: $default-color;
      }
    }

    .AppHeding {
      margin-top: 15px;
      font-size: $font-12;
      font-weight: bold;
      color: black;
    }
    .AppLink {
      width: 100%;
      display: flex;
      justify-content: left;
      align-items: center;
      text-align: center;
      gap: 6px;
      margin-top: -20px;
      .appText {
        margin-top: 10px;
        font-size: $font-11;
        color: black;
        text-align: center;
      }
      .appimg {
        cursor: pointer;
        width: 95px;
      }
    }
    .Footer_Head {
      font-family: sans-serif;
      font-size: $font-24;
      line-height: 24px;
      letter-spacing: normal;
      color: $maintheme-color;
      font-weight: 700;
    }

    .Aboutus_text {
      padding-top: 20px;
    }

    a {
      text-decoration: none;
      font-family: sans-serif;
      line-height: 1.5;
      color: $textcolor-four;
      font-size: $font-15;
    }
  }
}

.IconHover:hover .HoverIcon {
  color: $maintheme-color;
}

.HoverIcon:hover {
  color: $maintheme-color;
}

.Footer_subdiv {
  border: 1px solid $default-color;
  height: 200px;
}

.Footer_Info_Div {
  display: flex;
  justify-content: space-around;
}

@media only screen and (max-width: 1200px) {
  .Footer_Main_div {
    display: block;
    text-align: center;

    .infoDiv {
      width: 90%;
      margin: auto;

      img {
        width: 70%;
      }

      .SocialMediaLink {
        margin: auto;
      }
    }
  }
}
