@import "../../../../../resource/styles/variables/variables.module.scss";

td {
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: $third-font;
  font-size: $font-14;

  .awardedBnDiv {
    gap: 10px;
  }

  
}

.alignright {
  text-align: right;
}
.alignleft {
  text-align: left;
}
