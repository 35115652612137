@import "../../../resource/styles/variables/variables.module.scss";

.cnfModalContentBox{
    border-top: 1px solid $boxborder-color;
    padding: 5px;
    padding-right: 20px;


    .cnfModalText{
        text-align: justify;
        margin: 0;

    }
}