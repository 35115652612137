@import "../.././../resource/styles/variables/variables.module.scss";

.ImageBox {
  position: relative;
  width: 100%;
  height: 700px;
  margin-top: 50px;

  .ImageText {
    width: 100%;

    font-family: Prompt, sans-serif;
    position: absolute;
    top: 20%;
    left: 5%;
    font-weight: 700;
    font-size: $font-48;
  }

  .BookNowBtn {
    padding: 15px 20px;
    position: absolute;
    top: 60%;
    left: 7%;
    background-color: $maintheme-color;
    letter-spacing: 1.4px;
    font-size: $font-16;
    font-weight: 500;
    color: $text-light;
    cursor: pointer;
  }

  .BookNowBtn:hover {
    background-color: $text-light;
    color: $maintheme-color;
    border: 1px solid $maintheme-color;
  }
}

.ImageBox img {
  width: 100%;
  opacity: 1;
  height: 100%;
}

.TrackingDiv {
  width: 50%;
  height: 124px;
  float: right;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: $text-light;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border: 1px solid $textcolor-error;

  div {
    display: flex;
    align-items: center;
    border: 1px solid $textcolor-error;

    p {
      font-family: sans-serif;
      font-size: $font-22;
      line-height: 26.4px;
      padding-left: 20px;
      color: $maintheme-color;
    }
  }

  img {
    background: transparent;
    opacity: 1;
    width: 20%;
  }
}

@media only screen and (max-width: 1200px) {
  .ImageText {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .BookNowBtn {
    position: absolute;
    margin-top: 6rem;
  }

  .TrackingDiv {
    width: 80%;
    grid-template-columns: repeat(2, 1fr);
  }
}