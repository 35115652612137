@import "../../../../../../resource/styles/variables/variables.module.scss";

.SpContainer {
  .detailssection{
    max-width: 250px;
  }
  .quoteandtimer {
    width: 16%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .liveflexbox {
    gap: 100px;
  }
}
