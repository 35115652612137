@import "../../../../resource/styles/variables/variables.module.scss";

.bodyid {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 100%;

  .newLoadDiv {
    padding: 1px 5px;
    background-color:  $twelve-color;
    border-radius: 5px;

    .newLoadText {
      margin: 0;
      font-size: $font-12;
      color: $text-light;
      font-weight: 500;
    }
    
    animation: blinkAnimation 1.5s infinite;

    @keyframes blinkAnimation {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  
  }

  .idText {
    display: flex;
    align-items: center;
    gap: 10px;
    color: $primary-color;
    margin: 0px 0px 3px 0px;
    padding: 0px;
    font-weight: bold;
    font-size: $font-16;

    .actionDiv {
      display: flex;
      align-items: center;
      gap: 5px;

      .loadCloseBtn {
        cursor: pointer;
      }
    }
  }
}
