@import "../../../resource/styles/variables/variables.module.scss";

.tpLabel {
  margin: 0;
  font-size: $font-11;
  color: $label-color-one;
  font-family: $third-font;
  font-weight: bold;
  padding-left: 3px;
}

.time-picker-container {
  border: none;
  position: relative;
  margin-top: -3px;

  .rc-time-picker {
    position: relative;
    // margin-top: -10px;
    .rc-time-picker-input {
      border: none;
      border-bottom: 1px solid $border-color;
      border-radius: 0px;
      height: 19px;
      padding: 0px 3px;
      font-size: $font-13;
      color: $textcolor-one;
      // margin-top: -12px;
      cursor: pointer;

      &::placeholder {
        font-size: $font-13;
        white-space: nowrap;
        color: $textcolor-eight;
      }

      &:focus {
        outline: none !important;
      }
    }
  }
}

.time-picker-container1 {
  border: none;
  position: relative;
  // margin-top: 0%;

  margin-top: -3px;

  .rc-time-picker {
    position: relative;
    margin-top: -12px;
    .rc-time-picker-input {
      border: none;
      border-bottom: 1px solid $border-color;
      border-radius: 0px;
      height: 19px;
      padding: 0px 3px;
      font-size: $font-13;
      color: $textcolor-one;
      cursor: pointer;

      &::placeholder {
        font-size: $font-13;
        white-space: nowrap;
        color: $textcolor-eight;
      }

      &:focus {
        outline: none !important;
      }
    }
  }
  .rc-time-picker_err {
    position: relative;

    .rc-time-picker-input {
      border: none;
      border-bottom: 1px solid red;
      border-radius: 0px;
      height: 19px;
      padding: 0px 3px;
      font-size: $font-13;
      color: $textcolor-one;
      cursor: pointer;

      &::placeholder {
        font-size: $font-13;
        white-space: nowrap;
        color: $textcolor-eight;
      }

      &:focus {
        outline: none !important;
      }
    }
  }
}

.rc-time-picker-panel {
  position: absolute;
  top: 20px;
  left: 0;
  z-index: 200;
  // background-color: #fff;
  border: none;
  box-shadow: none;
}

.errmessage {
  font-size: $font-12;
  color: $textcolor-three;
  margin: 0px;
  white-space: nowrap;
}
