@import "../../../../resource/styles/variables/variables.module.scss";

.cardfooter {
  border-radius: 0px 0px 30px 30px;

  .flexbox {
    display: flex;
    justify-content: space-between;
    background-color: $seven-color;
    border-bottom: 1px solid $boxborder-color;
    padding: 10px 20px;
  }
  .liveflexbox {
    display: flex;
    justify-content: space-between;
    background-color: $seven-color;
    padding: 10px 20px;
    border-radius: 0px 0px 30px 30px;
  }
}
