@import "../../../../resource/styles/variables/variables.module.scss";

.Quote {
  margin-top: 0px;

  .quote {
    .quoteprice {
      margin: auto;
      background-color: $six-color;
      box-shadow: $bordershadow-color;
      border-radius: 10px;
      margin-top: 5px;
      gap: 5px;
      padding: 18px 10px;

      .noBidText {
        margin: 0;
        font-size: $font-14;
        font-weight: 500;
      }
      .bidPrice {
        margin: 0;
        font-size: $font-16;
        font-weight: 500;
      }
      .l1 {
        margin: 0;
        padding: 0;
        color: $textcolor-two;
        font-size: $font-16;
        font-weight: bold;
      }
    }
  }

  .ceilingprice {
    margin: 0;
    color: $textcolor-eight;
    font-size: $font-14;
    margin-top: 2px;
    margin-left: 5px;
  }
}
