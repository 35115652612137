@import "../../../resource/styles/variables/variables.module.scss";

.layout {
  width: 80%;
  margin: auto;
  min-height: calc(100vh - 282px);
}

.adjustPage {
  position: relative;
  left: 226px;
  width: 82%;
  min-height: 140.5vh;
}
