@import "../../../../resource/styles/variables/variables.module.scss";

.noOfVehiclesModal {
  padding: 10px 10px;
  border-top: 1px solid $boxborder-color;
  .firstDiv {
    display: flex;
    justify-content: space-between;
  }
  .eachModalContentDiv {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
    margin-top: 5px;

    .modalText {
      margin: 0;
      font-size: $font-14;
      font-weight: 500;
      width: 150px;
    }

    .cnfQtyDiv {
      display: flex;
      justify-content: left;
      align-items: center;
      font-size: $font-14;
      gap: 5px;
      width: 30%;
    }

    .selectDiv {
      width: 18%;

      .errmessage {
        margin: 0px;
        font-size: $font-12;
        color: $textcolor-three;
        white-space: nowrap;
      }

      .basicSelectTag {
        border: none;
        border-bottom: 1px solid;
        width: 85px;
        outline: none !important;
        cursor: pointer;
      }
    }

    .selectDivTwo {
      display: grid;
      grid-template-row: repeat(3, 1fr);
      gap: 10px;
      width: 100%;

      .eachSelectBoxDiv {
        margin-top: 10px;

        .selectVehicleDiv {
          width: 90%;
          margin: auto;
          display: flex;
          justify-content: flex-start;
          gap: 80px;

          .vehicleSelect {
            width: 150px;
            padding: 5px;
          }
          .permtInput {
            width: 110px;
            padding: 5px;
            font-size: $font-12;
            &:focus {
              outline: none !important;
            }
          }
          .no-spinners {
            -moz-appearance: textfield;
          }
        }
      }
    }

    .selectDivThree {
      width: 80%;

      .textArea {
        width: 70%;
        min-height: 50px;
        max-height: 100px;
      }
    }
  }
}
