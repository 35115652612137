@import "../../../resource/styles/variables/variables.module.scss";

.walletBalanceDiv {
  height: 20px;
  padding: 4px 13px;
  border-radius: 10px;
  background-color: $eight-color;
  gap: 5px;

  .balTextDiv {
    gap: 5px;
    .walletBalanceHeading {
      font-size: $font-15;
      font-weight: bold;
    }
    .walletBalanceContent {
      font-size: $font-13;
      font-weight: bold;
      color: $textcolor-seven;
    }
    .lowBal {
      font-size: $font-13;
      font-weight: bold;
      color: $textcolor-error;
    }
  }

  .addWalletBalDiv {
    position: relative;

    .addIcon {
      color: $textcolor-three;
      font-size: $font-18;
      cursor: pointer;
    }

    .popupDiv {
      position: absolute;
      background-color: $background-color;
      box-shadow: $bordershadow2-color;
      border: 1px solid $boxborder-color;
      z-index: 40;
      width: 150px;
      top: 29px;
      right: -15px;
      padding: 5px 10px;
      border-radius: 10px;
    }
  }
}
