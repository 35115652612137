@import "../../../../resource/styles/variables/variables.module.scss";

.withdrawnBalanceModalDiv {
  border-top: 1px solid $boxborder-color;

  .withdrawnBalanceEachDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 10px;

    .withdrawnBalanceHeading {
      margin: 0;
      font-size: $font-15;
      font-weight: 500;
    }
    .withdrawnBalanceContentText {
      margin: 0;
    }
  }
}
