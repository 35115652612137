@import "../../../../../../resource/styles/variables/variables.module.scss";

.SpFinalResultBox {
  padding: 20px;
  box-shadow: $bordershadow-color;
  background-color: $six-color;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 10px;

  .greenRankText {
    margin: 0;
    font-weight: 500;
    font-size: $font-18;
    color: $textcolor-two;
  }

  .finalQuotePriceText {
    margin: 0;
    text-align: center;
    font-size: $font-16;
    font-weight: bold;
  }

  .finalQuoteRankText {
    margin: 0;
    text-align: center;
    font-size: $font-16;
    font-weight: bold;
    color: $textcolor-two;
  }
}

.noBidPlaced{
  margin: 0;
  font-weight: 500;
  font-size: $font-14;
}
