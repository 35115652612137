@import "../../../resource/styles/variables/variables.module.scss";

.bidNoteDiv {
  width: 100px;
  margin: auto;
  margin-top: 10px;
  height: auto;
  background-color: $six-color;
  border-radius: 10px;
  padding: 10px;

  .bidNoteHeading {
    margin: 0;
    font-size: $font-14;
    font-weight: bold;
    word-wrap: break-word; 
  }

  .bidNoteText {
    margin: 0;
    word-wrap: break-word;    
    font-size: $font-14;
    text-align: justify;
  }
}
