@import "../../../../../../../resource/styles/variables/variables.module.scss";

.statusBoxSP {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 5px;
  max-height: 20px;

  .stausBoxSPCheckbox {
    margin: 5px;
  }

  .contentText {
    font-size: $font-14;
    margin: 0;
  }

  .showText {
    margin: 0;
    font-size: $font-14;
    color: $textcolor-error;
    text-decoration: underline;
    cursor: pointer;
  }
}
