@import "../../../resource/styles/variables/variables.module.scss";

.AboutUs_MainDiv {
  text-align: center;
  width: 100%;
  margin-top: 20px;

  div {
    width: 90%;
    margin: auto;
  }
}

.AboutUs {
  width: 10%;
  margin: auto;
  padding: 6px 14px;
  font-size: $font-12;
  line-height: 24px;
  letter-spacing: 1.8px;
  color: $maintheme-color;
  font-weight: 500;
  background-color: $background-color;
}

.AboutUs_text p:nth-child(2) {
  font-family: Prompt, sans-serif;
  font-size: $font-36;
  line-height: 48px;
  text-align: center;
  letter-spacing: normal;
  font-weight: 600;
  padding-bottom: 20px;
  padding-top: 20px;
}

.AboutUs_text p:nth-child(3),
.AboutUs_text p:nth-child(4) {
  font-family: Prompt, sans-serif;
  font-size: $font-16;
  line-height: 24px;
  letter-spacing: normal;
  color: $textcolor-four;
  padding-bottom: 20px;
}

@media only screen and (max-width: 1200px) {
  .AboutUs {
    white-space: nowrap;
    background-color: $text-light;
  }
}
