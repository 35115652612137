@import "../../../../../../resource/styles/variables/variables.module.scss";
.SpContainer{

  .resultTable {
    background-color: $secondary-colorlite;
    border-radius: 0px 0px 30px 30px;
    padding: 10px 20px;
  
    .resultHeader {
      margin: 0;
      font-size: $font-15;
      font-weight: bold;
      color: $textcolor-three;
    }
  
    .resultTableDiv {
      .resultTableflexbox {
        margin: 0;
        display: flex;
        align-items: center;
        gap: 100px;
      }
    }
  }
  
  .liveflexbox {
    gap: 100px;
  }
}


