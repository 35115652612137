@import "../../../../../../resource/styles/variables/variables.module.scss";

.userProfilePage {
  width: 100%;
  padding: 5px;

  .userProfilePageHeading {
    margin: 10px;
    font-size: $h3;
    color: $textcolor-three;
  }

  .userProfilePageContent {
    height: 100%;
    border: 1px solid $boxborder-color;
    border-radius: 10px;

    .userDetailName {
      display: flex;
      justify-content: space-around;
    }

    .profileEdit {
      display: flex;
      justify-content: space-between;
      padding: 10px;

      .profileEditContent {
        display: inline;

        width: 85%;

        .profileHeading {
          font-size: $font-24;
          margin: 10px;
          font-weight: 500;
          display: flex;
          align-items: center;
          gap: 5px;
          color: $default-color;
        }

        .contentText {
          margin: 10px;
          display: flex;
          align-items: center;
          gap: 5px;
          color: $textcolor-four;
        }
      }

      .saveBtnBox {
        width: 10%;

        .editBtn {
          color: $primary-color;
          border: 1px solid $primary-color;
          border-radius: 5px;
          font-weight: 600;
          gap: 4px;
          cursor: pointer;
          margin-right: 30px;
        }
      }
    }

    .contentBox {
      display: inline;
      margin-top: 20px;

      .profilFields {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 97%;
        margin: auto;

        .fieldsData {
          width: 100%;

          .detailsBox {
            display: flex;
            padding: 12px;
            align-items: center;
            margin-top: -30px;
            justify-content: flex-start;

            .detailsBoxName {
              width: 52%;
              display: flex;
              align-items: center;

              .contentText {
                margin: 10px;
                font-size: $font-14;
                display: flex;
                padding: 2;
                align-items: center;
                gap: 5px;
                color: $textcolor-four;
              }

              .editableAlert {
                font-size: $font-14;
                align-items: center;
                color: $eleven-color;
              }
            }

            .detailsBoxContent {
              width: 100%;
              display: flex;
              gap: 1px;
              padding: 2;
              align-items: center;
              justify-content: flex-start;

              .inputDiv {
                display: inline;
                // border: 1px solid black;

                .editable {
                  width: 100%;
                  margin: 10px;
                  font-size: $font-14;
                  margin-top: 11px;
                  font-weight: 600;
                  align-items: center;
                  gap: 5px;
                  color: $default-color;
                  border: 0px solid transparent;
                  background: none;
                  cursor: pointer;
                  outline: none;
                }

                .editableTextArea {
                  width: auto;
                  margin: 10px;
                  font-size: $font-14;

                  font-weight: 600;
                  align-items: center;
                  gap: 5px;
                  color: $default-color;
                  border: 0px solid transparent;
                  background: none;
                  cursor: pointer;
                  outline: none;
                  margin-left: -2px;
                }

                .editable1 {
                  width: 100%;
                  margin: 10px;
                  font-size: $font-14;
                  margin-top: 8px;
                  font-weight: 600;
                  align-items: center;
                  gap: 5px;
                  color: $eleven-color;
                  border: 0px solid transparent;
                  background: none;
                  outline: none;
                  // text-transform: capitalize;
                }

                .txtCapitalization {
                  text-transform: capitalize;
                }

                .hrTagEdit {
                  width: 100%;
                  margin-left: 11px;
                  margin-top: -5px;
                  margin-bottom: 0px;
                  border-top: 1px solid $default-color;
                }
              }
            }
          }

          .errdiv {
            width: 95%;
            margin: auto;

            .error {
              font-size: $font-13;
              color: $textcolor-three;
              margin-top: 0px;
              margin-left: 10px;
              margin-bottom: 0px;
            }
          }
        }

        .filessection {
          align-items: center;
          width: 28%;
        }
      }

      .hrTag {
        margin-left: 20px;
        margin-top: -16px;
      }
    }

    .bothBtnDiv {
      display: flex;
      justify-content: flex-end;
      gap: 5;

      .saveBtnBox {
        display: flex;
        justify-content: right;
        padding: 10px 10px;
      }
    }
  }

  .userStatus {
    box-shadow: $bordershadow2-color;
    border-radius: 10px;
    padding: 2%;
    margin: 10px;

    .statusHeadingDiv {
      width: 100%;
      display: flex;
      justify-content: space-between;

      // border: 1px solid black;
      .statusSubHeading {
        font-size: $font-14;
        margin: 10px;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 5px;
        color: $textcolor-four;
      }

      .bothBtnDiv {
        display: flex;
        justify-content: space-between;

        .activeDiv {
          margin-top: 18%;
          color: $background-color;
          background-color: $twelve-color;
          border-radius: 8px;
          padding: 7px 14px 7px 14px;
          font-weight: bold;
          border: none;
        }

        .notActiveDiv {
          margin-top: 16%;
          color: $background-color;
          background-color: $eleven-color;
          border-radius: 8px;
          padding: 7px 14px 7px 14px;
          font-weight: bold;
          border: none;
        }
      }
    }

    .statusDetailsBar {
      width: 50%;
      margin: 10px;
      margin-top: -10px;
    }

    .statusDetails {
      background-color: $background3-color;
      border-radius: 10px;
      padding: 1%;
      margin: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 10;
      padding-bottom: 0;
      margin-top: 30px;

      .statusDetailFields {
        display: flex;
        justify-content: space-between;
        padding: 1%;

        .contentText {
          margin: 10px;
          margin-top: -2px;
          font-size: $font-14;
          display: flex;
          font-weight: bold;
          align-items: center;
          gap: 5px;
          color: $textcolor-ten;
          margin-right: 40px;
        }
      }
    }
  }

  .bothBtnDiv {
    display: flex;
    justify-content: flex-end;
    gap: 5;

    .saveBtnBox {
      display: flex;
      justify-content: right;
      padding: 10px 10px;
    }
  }
}