@import "../../../../../../resource/styles/variables/variables.module.scss";

.dateDetailsEachBox {
  max-width: 27%;

  .heading {
    border-bottom: 1px solid $border-color;
    .headingText {
      color: $textcolor-one;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  .belowbox {
    margin-top: 5px;
    .contentText {
      min-height: 20px;
    }
  }
}
