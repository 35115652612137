@import "../../../resource/styles/variables/variables.module.scss";

.notificationBox {
  
  .notificationDiv {
    justify-content: left;
    padding: 2px 6px;
    gap: 10px;
    border-bottom: 0.5px solid $boxborder-color;

    .notificationIcon {
      color: $border-color;
      font-size: $font-20;
    }

    .notificationText {
      margin: 0;
      padding: 5px;
      font-size: $font-14;
    }
  }
}
