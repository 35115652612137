@import "../../../../../resource/styles/variables/variables.module.scss";

.VRAUPModalDiv {
  border-top: 1px solid $boxborder-color;

  .eachContentDiv {
    .contentMainHeading {
      font-size: $font-14;
      font-weight: bold;
      color: $primary-color;
      margin: 10px 0px 0px 10px;
    }
  }
  .fileUploadDiv {
    margin-top: 5px;
    margin-bottom: 10px;
  }
}
