@import "../../resource/styles/variables/variables.module.scss";

.termsNContainerDiv {
  padding: 0;
  padding: 20px 0;
  width: 85%;
  margin: auto;
  min-height: 100dvh;

  .termsMain {
    display: flex;
    flex-direction: column;

    svg {
      cursor: pointer;
    }

    h2 {
      margin: 0;
      text-align: center;
      text-decoration: underline;
    }

    .termsContainer {
      display: flex;
      flex-direction: column;
      gap: 30px;
      margin-top: 50px;

      h2 {
        margin: 0;
      }

      .termBox {
        h3 {
          margin: 0;
        }
        p {
          margin: 0;
          color: $textcolor-four;
          font-size: $font-15;
          margin-top: 10px;
        }
      }
    }
  }
}
