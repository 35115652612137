@import "../../../../../../resource/styles/variables/variables.module.scss";

.amountBoxEachBox {
  max-width: 27%;

  .eachContentDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    line-height: 1.4;

    .headingText {
      margin: 0;
      font-size: $font-14;
    }
  }

  .heading {
    border-bottom: 1px solid $border-color;
    .headingText {
      color: $textcolor-one;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  .contentText {
    margin: 0;
    font-size: $font-14;
  }
  .freightAmount {
    font-weight: bold;
  }
  .belowbox {
    margin-top: 5px;
  }
}
