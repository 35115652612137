@import "../../../../resource/styles/variables/variables.module.scss";

.ongoingPage {
  .eachBox {
    line-height: 0.3;

    .heading {
      border-bottom: 1px solid $border-color;
      .headingText {
        color: $textcolor-one;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
    .contentText {
      font-size: $font-14;
    }
    .loadIdLink {
      
      
      .loadIdNumber {
        color: $textcolor-three;
        font-size: $font-14;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .freightAmount {
      font-weight: bold;
    }
    .belowbox {
      margin-top: 12px;
    }
  }
}
