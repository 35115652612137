@import "../../../../../../resource/styles/variables/variables.module.scss";

.userComapnyPage {
  width: 100%;
  padding: 5px;


  .userComapnyPageHeading {
    margin: 10px;
    font-size: $h3;
    color: $textcolor-three;
  }

  .userCompanyPageContent {
    border: 1px solid $boxborder-color;
    border-radius: 10px;
    padding: 20px 10px 20px 10px;

    .companyEachDetails {
      justify-content: left;
      gap: 10px;
      font-size: $font-15;

      .userCompanyEachDetailHeading {
        margin: 0;
        font-weight: 500;
        color: $textcolor-three;
      }

      .userCompanyEachDetailContent {
        margin: 0;
      }
    }
  }
}
