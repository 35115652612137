@import "../../../resource/styles/variables/variables.module.scss";

.selecttagbox {
  .label {
    font-size: $font-13;
    color: $textcolor-four;
    font-family: $third-font;
    font-weight: bold;
  }
  .errdiv {
    width: 95%;
    margin: auto;

    .error {
      font-size: $font-13;
      color: $textcolor-three;
      margin-top: 0px;
      margin-left: 10px;
      margin-bottom: 0px;
    }
  }
  .selecttag {
    width: 100%;
    padding: 6px;
    margin-top: 4px;
    color: $textcolor-four;
    border: none;
    border-bottom: 1px solid $border-color;
    font-family: $primary-font;
    font-size: $font-14;
    cursor: pointer;
    font-weight: 550;

    &:focus {
      outline: none !important;
    }
  }
  .filterSelectTag {
    width: 100%;
    padding: 3px;
    margin-top: 2px;
    color: $textcolor-four;
    border: none;
    border-bottom: 1px solid $border-color;
    font-family: $third-font;
    font-size: $font-12;
    cursor: pointer;

    &:focus {
      outline: none !important;
    }
  }
  .formSelect {
    width: 15%;
    margin-bottom: 10px;
    cursor: pointer;
    color: $textcolor-eight;
    border: none;
    border-bottom: 1px solid $border-color;
    font-size: $font-13;
    margin-left: 10px;

    &:focus {
      outline: none !important;
    }
  }
}
