@import "../../resource/styles/variables/variables.module.scss";

.bgImg {
  background-image: url("../../Assets/Images/Main\ Sign\ Up\ Page\ Final.jpg");
  background-size: contain;
  background-repeat: no-repeat;
}
.helplineNoSignupText {
  margin: 0;
  margin-top: 10px;
  text-align: center;
  font-size: $font-14;
  font-weight: 500;
}
.signuppage {
  width: 100%;
  height: 100vh;

  .signupbox {
    width: 250px;
    align-items: center;
    background-color: $background-color;
    border-radius: 10px;
    padding: 20px 15px;
    margin: 50px 50px 0 0;
    overflow: hidden;
    box-shadow: 0 0 38px -11px $bordershadow-color;
    float: right;

    .emtLogoDiv {
      width: 95%;
      margin: auto;
    }

    .boxcenter {
      display: flex;
      justify-content: center;
      position: relative;
      height: 100%;
      background: $background-color;
      margin-top: 20px;

      .Icon-inside {
        width: 90%;
        margin: auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .form_icons {
          font-size: $font-18;
          color: $textcolor-four;
        }

        .eyeIcon {
          font-size: $font-22;
          color: $border-color;
          cursor: pointer;
        }
        i {
          position: absolute;
          left: 0;
          top: 5px;
          padding: 5px 15px 9px;

          #emailicon {
            font-size: $font-20;
          }
        }
      }

      .btnbox {
        margin-top: 10px;
      }
      .mobileVerify {
        text-align: center;
        margin-top: -8px;
        .mobileVerifyP {
          font-size: $font-14;
          color: $textcolor-four;
          .mobileVerifyPSpan {
            color: blue;
          }
        }
      }
      .mobileVerifyInput {
        display: flex;
        justify-content: center;
      }
      .mobileVerifyBtn {
        display: flex;
        justify-content: center;
        margin-top: 10px;
      }
      .errdivotp {
        width: 100%;
        margin: auto;
        align-items: center;
        .errorotp {
          font-size: $font-12;
          color: $textcolor-error;
          margin-top: 0px;
          margin-bottom: 0px;
          white-space: nowrap;
          text-align: center;
        }
      }
      .backDiv {
        margin-top: -15px;
        width: 30px;
        height: 25px;
        .IconsDiv {
          // padding: 5px;
          // border: 1px solid $boxborder-color;
          cursor: pointer;

          :hover {
            color: $primary-color;
          }

          .backFilters {
            margin: auto;
            font-size: $font-20;
          }
        }
      }
      .resendOtp {
        text-align: right;
        margin-top: 4px;
        .resendOtpP {
          font-size: $font-12;
          .resendOtpPspan {
            text-decoration: underline;
            color: green;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen-xs) {
  .signuppage {
    height: 845px;
  }
}

@media screen and (min-width: $screen-xs) and (max-width: $screen-sm) {
  .signuppage {
    height: 1025px;
  }
}
