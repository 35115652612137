@import "../../resource/styles/variables/variables.module.scss";

.privacyPolicyDiv {
    padding: 0;
    padding: 20px 0;
    width: 85%;
    margin: auto;

    svg {
        cursor: pointer;
    }

    h1 {
        margin: 2rem 0;
        text-align: center;
    }

    .termsContainer {
        display: flex;
        flex-direction: column;
        gap: 30px;

        h2{
            margin: 0;
        }

        .termBox {
            h3{
                margin: 0;
            }
            p {
                margin: 0;
                color: $textcolor-four;
                font-size: $font-15;
                margin-top: 5px;
            }
        }
    }
}