@import "../../../resource/styles/variables/variables.module.scss";

.CIMDiv {
  .imLabel {
    margin: 0;
    font-size: $font-11;
    color: $label-color-one;
    font-family: $third-font;
    font-weight: bold;
    padding-left: 3px;
  }

  input {
    border: none;
    border-bottom: 1px solid $border-color;
    outline: none !important;
  }
  .editable {
    width: 100%;
    margin: 10px;
    font-size: $font-14;
    margin-top: 11px;
    font-weight: 600;
    align-items: center;
    gap: 5px;
    color: $default-color;
    border: 0px solid transparent;
    background: none;
    cursor: pointer;
    outline: none;
    text-transform: capitalize;
  }
  .no-spinners {
    -moz-appearance: textfield;
  }
}
