@import "../../../../../../resource/styles/variables/variables.module.scss";

.currentTaskDiv {
  padding-left: 10px;

  .currentTaskHeader {
    color: $textcolor-three;
    font-size: $font-14;
    text-decoration: underline;
    font-weight: bold;
    margin: 0;
    margin-top: 5px;
  }

  .taskMessage {
    font-size: $font-14;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .taskBtnDiv {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .lpCurrentTaskBtnDiv {
    display: flex;
    gap: 10px;
  }

  .actionBtnDiv {
    display: flex;
    align-items: center;
    gap: 10px;

    .lpClaimBtnDiv {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}
