@import "../../../../resource/styles/variables/variables.module.scss";

.BankModalDiv {
  border-top: 1px solid $boxborder-color;
  margin-bottom: 5px;

  .BankModalMessageDiv {
    padding: 20px 10px;
  }
  div {
    // margin-bottom: 10px;
  }
  
  .reinputdiv {
    display: flex;
    gap:10px;
    font-weight: bold;
  }
  
  .acInput {
    padding: 5px;
    width: 150px;
    // margin-left: 10px;
    border-bottom: 1px solid $default-color;
  }
  .acInput:focus {
    outline: none;
  }
  .BankModalMessage {
    margin: 0;
    font-size: $font-15;
    font-weight: 500;
  }
  .bankdetaisDiv{
    display: block;
    gap: 0px;
  }
}
