@import "../../../resource/styles/variables/variables.module.scss";

.emtlogo {
  width: 100%;
}

.fileUploadImage {
  cursor: pointer;
}
.fileUploadIcon {
  height: 70px;
  width: 70px;
  cursor: pointer;
}
