@import "../../../../resource/styles/variables/variables.module.scss";

.completedPage {
  .eachBox {
    line-height: 0.3;

    .heading {
      border-bottom: 1px solid $border-color;
      .headingText {
        color: $textcolor-one;
        font-weight: 500;
        margin-bottom: 10px;
      }
    }

    .contentText {
      font-size: $font-14;
    }
    .freightAmount {
      font-weight: bold;
    }
    .belowbox {
      margin-top: 12px;
    }
  }
}
