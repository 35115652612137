@import "../../../../resource/styles/variables/variables.module.scss";

.headericonsection {
  gap: 10px;

  .notificationIconBox {
    .bellicon {
      font-size: $font-22;
      color: $five-color;
      cursor: pointer;
    }
  }

  .profileIconBox {
    position: relative;

    .profileicon {
      color: $five-color;
      height: 25px;
      width: 30px;
      cursor: pointer;
    }
    .greetingDiv {
      .activeDiv {
        width: 70%;
        margin: auto;
        font-size: $font-11;
        font-weight: 700;
        color: $text-light;
        background-color: $eleven-color;
        padding: 2px 5px;
        border-radius: 10px;
        cursor: pointer;
      }

      .notActiveDiv {
        width: 70%;
        margin: auto;
        font-size: $font-11;
        font-weight: 700;
        color: $text-light;
        background-color: $twelve-color;
        padding: 2px 1px;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }
}
.activeDivModal {
  font-size: $font-13;
  color: $textcolor-three;
  margin-top: 0px;
  margin-left: 10px;
  margin-bottom: 5px;
}

.modalCloseBtn {
  color: $primary-color;
  border: 1px solid $primary-color;
  border-radius: 5px;
  padding: 5px 14px 5px 14px;
  margin-top: 20px;
  font-weight: 600;
  cursor: pointer;
  margin-left: 100px;
}

.modalHeadinginfo {
  margin-top: 0px;
  font-size: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid black;
  padding: 10px;
}

.modalProfileBtn {
  background-color: $primary-color;
  color: $text-light;
  padding: 5px 14px 5px 14px;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  border: none;
}

// .modalCloseBtn {
//   background-color: #e5e5e5;
//   color: $primary-color;
//   padding: 6px 14px;
//   border-radius: 4px;
//   border: 1px solid $primary-color
// }

/* Add more CSS rules for other elements as needed */
