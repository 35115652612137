@import "../../../../../../resource/styles/variables/variables.module.scss";

.LpContainer {

  .detailssection{
    max-width: 250px;
  }

  .ceilingprice {
    margin: 0;
    font-size: $font-13;
    margin-top: 10px;
    font-weight: 500;
    color: $textcolor-eight;
  }

  .quoteandtimer {
    width: 16%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .liveflexbox {
    gap: 100px;
  }
}
