@import "../../../resource/styles/variables/variables.module.scss";

.filterContent {
  padding: 0px 10px;
  margin-bottom: 20px;
  // border: 1px solid black;
  .searchDiv{
    display: flex;
    margin: 5px;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    
  }
  .searchHeaderDiv{
    justify-content: space-between;
    border-bottom: 1px solid $boxborder-color;
    // background-color: $seven-color;

    .filterText {
      margin: 5px;
      font-size: $font-20;
      font-weight: bold;
    }
  }
  .filterHeaderDiv {
    margin-top: 10px;
    // border-top: 1px solid $boxborder-color;
    justify-content: space-between;
    border-bottom: 1px solid $boxborder-color;
    // background-color: $seven-color;

    .filterText {
      margin: 5px;
      font-size: $font-20;
      font-weight: bold;
    }
    .reset {
      display: flex;
      align-items: center;
      font-size: $font-14;
      color: $text-light;
      cursor: pointer;
      // border: 1px solid black;
      padding: 4px;
      padding-left: 6px;
      background-color: $primary-color;
      border-radius: 10px;
      font-weight: 600;
      .resetIcon {
        color: $background-color;
        font-size: $font-18;
      }
    }
  }

  .filtersInputDiv {
    margin: 5px;
    align-items: center;
    justify-content: center;


    .inputBoxDiv {
      margin-top: 10px;
      width: 100%;
    }
  }
}

.displayhidden {
  display: none;
}
