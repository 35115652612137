@import "../../../../resource/styles/variables/variables.module.scss";

.confirmModalDiv {
  border-top: 1px solid $boxborder-color;

  .confirmModalMessageDiv {
    padding: 20px 10px;
  }

  .confirmModalMessage {
    margin: 0;
    font-size: $font-15;
    font-weight: 500;
  }
}
