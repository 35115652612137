@import "../../../resource/styles/variables/variables.module.scss";

.loadingText {
  min-height: 400px;
  gap: 10px;
  font-size: $font-20;
  font-weight: 500;
  .truckIcon {
    font-size: $font-26;
    color: $primary-color;
  }
}
