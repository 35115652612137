@import "../../../resource/styles/variables/variables.module.scss";

.footer {
  width: 100%;
  box-shadow: $bordershadow5-color;
  margin-top: 20px;

  .footerContent {
    width: 95%;
    margin: auto;
    gap: 20px;

    .phonedetails {
      font-size: $font-15;
      font-weight: 500;
      gap: 5px;

      .phoneIcon {
        font-size: $font-14;
      }
    }
    .emaildetails {
      font-size: $font-15;
      font-weight: 500;
      gap: 5px;

      .emailIcon {
        font-size: $font-16;
      }
    }
  }

  .lastDiv {
    width: 95%;
    margin: auto;
    padding-bottom: 20px;

    .lastDivText {
      text-align: center;
      font-size: $font-14;
      font-weight: 500;
      margin: 0;
      margin-top: 10px;
    }
  }
}
