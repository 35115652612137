@import "../../../../resource/styles/variables/variables.module.scss";

.file-upload-container {
  .formatFileUpload {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 20px 0px 10px;

    .uploadFileHeading {
      margin: 0;
      font-size: $font-14;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 5px;

      .checkIcon {
        color: $first-color;
      }
    }

    .formatFileUploadIcon {
      cursor: pointer;
      font-size: $font-20;
    }

    .fileUploadIcon2 {
      height: 70px;
      display: block;
      margin: auto;
      cursor: pointer;
    }
  }
}

  .childrenDiv {
    min-height: 200px;
    max-height: 450px;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-content: center;

    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $border-color;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: $five-color;
    }
    &::-webkit-scrollbar-track {
      background-color: $boxborder-color;
    }

    .videoPlayer {
      padding: 20px;
      display: block;
      margin: auto;
      height: 200px;
      width: 80%;
    }

    .filterUploadBoxContent2 {
      .iconDiv {
        padding: 10px 30px;

        .selectLangTag {
          margin-top: 20px;
        }

        .videoText {
          text-align: justify;
        }

        input {
          display: none;
        }
        .fileUploadIcon {
          display: block;
          min-height: 100px;
          min-width: 100px;
          margin: auto;
        }
      }
    }
  }

 

// .filterUploadBoxContent2 {
//   min-height: 200px;
//   .iconDiv {
//     padding: 10px 30px;

//     input {
//       display: none;
//     }
//     .fileUploadIcon {
//       display: block;
//       min-height: 100px;
//       min-width: 100px;
//       margin: auto;
//     }
//   }
// }