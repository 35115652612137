@import "../../../../resource/styles/variables/variables.module.scss";

.bidCModalContentBox {
  border-top: 1px solid $boxborder-color;
  padding: 5px;
  padding-right: 20px;

  .bidCModalText {
    text-align: justify;
    margin: 0;
  }
  .divtable {
    border: 1px solid black;
    align-items: center;
    margin-left: 13px;
    padding: 8px;
    margin-top: 8px;
  }
}
