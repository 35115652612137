@import "../../../../resource/styles/variables/variables.module.scss";

.dashboard {
  box-shadow: $bordershadow2-color;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  width: 22%;
  height: 525px;

  .navlink {
    text-decoration: none;
    font-size: $font-16;
    font-weight: 500;
    color: $textcolor-four;
    
  }
  .activePage {
    text-decoration: none;
    font-size: $font-16;
    font-weight: 500;
    color: $textcolor-three;
  }

  .dashboardComponent {
    justify-content: left;
    gap: 5px;
    padding-left: 10px;
    border-bottom: 1px solid $boxborder-color;
    cursor: pointer;


    &:hover {
      background-color: $background3-color;
    }
  }
}
