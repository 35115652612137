@import "../../../../../resource/styles/variables/variables.module.scss";

// .walletBalancePopupDiv {
//   position: absolute;
//   background-color: $background-color;
//   box-shadow: $bordershadow2-color;
//   border: 1px solid $boxborder-color;
//   z-index: 50;
//   width: 150px;
//   top: 29px;
//   right: -15px;
//   padding: 5px 10px;
//   border-radius: 10px;

//   .btnDiv {
//     gap: 10px;
//   }
// }

.walletBalancePopupDiv {
  .walletBalancePopupErrText {
    margin: 0;
    font-size: $font-12;
    color: $textcolor-error;
    font-weight: 500;
    text-align: center;
    margin-bottom: 5px;
  }
}
