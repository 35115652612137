@import "../../../../resource/styles/variables/variables.module.scss";

.lpLoadingdetailsbody {
  h5 {
    margin: 0px;
    padding: 0px;
  }
  .abc {
    justify-content: left;
    gap: 5px;
  }
  .loadingDetailsText {
    margin: 0;
    font-size: $font-14;
    list-style-type: none;
  }
  .flexText {
    display: flex;
    gap: 5px;
  }
  .odc {
    color: $textcolor-three;
    font-weight: 700;
  }
  .dg {
    color: $textcolor-three;
    font-weight: 700;
  }
}
