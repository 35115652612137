@import "../../../../resource/styles/variables/variables.module.scss";

.LoadPostTypeModalDiv {
  border-top: 1px solid $boxborder-color;
  justify-content: center;
  align-items: center;
  max-height: 450px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $border-color;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: $five-color;
  }
  &::-webkit-scrollbar-track {
    background-color: $boxborder-color;
  }

  .TypesOfLoadDiv {
    margin-top: 20px;
    margin-bottom: 20px;

    .descDiv {
      width: 88%;
      margin: auto;
      margin-bottom: 20px;
      align-items: center;
      justify-content: center;
      padding: 15px 20px;
      border-radius: 20px;
      box-shadow: $bordershadow2-color;
      font-size: $font-15;
      text-align: justify;
      cursor: pointer;

      .radio-btn {
        display: flex;
        justify-content: flex-start;

        .radioLable {
          width: 20%;
          margin: 0;
          margin-bottom: 5px;
        }
        .radioInput {
          width: 10%;
          margin-top: 6px;
        }
      }
    }
  }
}
