@import "../../../../resource/styles/variables/variables.module.scss";

.bookingDeatailsModal {
  padding: 5px 10px;
  border: 1px solid $boxborder-color;

  .bookingflex {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;

    .bookingDetailsFlex {
      width: 65%;
    }
    .amountDetailsFlex {
      width: 35%;
    }
  }
 
  .bookingmodalHeading {
    margin: 0;
    font-size: $font-14;
    font-weight: bold;
    color: $textcolor-three;
    margin-top: 5px;
  }
  .eachBookingDetailsDiv {
    justify-content: left;

    .bookingcontentText {
      justify-content: left;
      margin: 0;
      font-size: $font-14;
      font-weight: 500;
      width: 35%;
      margin-bottom: 5px;
    }
    .bookingDetailsData {
      margin: 0;
      font-size: $font-14;
      font-weight: 400;
      width: 65%;
      margin-bottom: 5px;
    }
    .loadIdLink {
      .loadIdNumber {
        color: $textcolor-three;
        font-size: $font-14;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .bookingcontentText {
    justify-content: left;
    margin: 0;
    font-size: $font-14;
    font-weight: 500;
    margin-bottom: 5px;
  }

 
}
