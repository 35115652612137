@import "../../../resource/styles/variables/variables.module.scss";

.headerdiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0px 10px;
  border-bottom: 1px solid $six-color;

  .logodiv {
    width: 250px;
    .emtlogo {
      width: 100%;
      height: 50px;
    }
  }

  .searchBoxDiv {
    display: flex;
    align-items: center;
    gap: 10px;

    .helpNoDiv {
      padding: 5px 10px;
      border-radius: 5px;
      border: 1px solid $boxborder-color;
      box-shadow: $bordershadow6-color;

      .helpLineNoText {
        margin: 0;
        font-size: $font-14;
        font-weight: 500;
        text-align: center;
        // font-style: italic;
      }
    }

    .search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      gap: 10px;
    }
  }

  .detailssection {
    display: flex;
  }
}
