@import "../../../resource/styles/variables/variables.module.scss";

.addUserTable {
  border-collapse: collapse;
  width: 100%;

  tr {
    &:hover {
      background-color: $eight-color;
    }
  }

  td,
  th {
    border: 1px solid $six-color;
    padding: 8px;
  }

  th {
    text-align: center;
    background-color: $primary-color;
    color: $text-light;
    // min-width: 100px;
  }

  td {
    text-align: center;
    color: $textcolor-four;
    overflow: hidden;

    .editIcon {
      cursor: pointer;
    }
  }
}
 