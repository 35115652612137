@import "../../../resource/styles/variables/variables.module.scss";

.lpModalFooter {
  height: 35px;
  display: flex;
  background-color: $background-color;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 0px 10px;
  border-top: 1px solid $boxborder-color;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: 0px;
}

.myModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $bordershadow3-color;
  z-index: 200;

  .addloadform-content {
    background-color: $background-color;
    width: 75%;
    margin: auto;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      height: 25px;

      .flex {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        .headingText {
          font-weight: 500;
        }

        .closeIcon {
          font-size: $font-18;
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
  }
}

// *******Notification Modal*********
.notificationModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $bordershadow3-color;
  z-index: 200;
  justify-content: right;

  .notification-content {
    background-color: $background-color;
    width: 25%;
    height: 660px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      height: 35px;
      border-bottom: 1px solid $boxborder-color;

      .flex {
        display: flex;
        gap: 10px;
        .headingText {
          font-size: $font-24;
          font-size: $third-font;
          font-weight: bold;
        }

        .closeIcon {
          font-size: $font-22;
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
  }
}

// *******File Upload Modal*********
.fileUploadModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background-color: $bordershadow4-color;

  .fileUpload-content {
    background-color: $background-color;
    width: 30%;
    border-radius: 5px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      height: 25px;
      border-bottom: 1px solid $boxborder-color;

      .flex {
        display: flex;
        gap: 10px;
        .headingText {
          font-size: $font-18;
          font-weight: 500;
        }

        .closeIcon {
          font-size: $font-22;
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
  }
}

// *******load Details Modal*********
.loadDetailModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $bordershadow3-color;
  z-index: 200;

  .loadDetailModal-content {
    background-color: $background-color;
    width: 60%;
    margin: auto;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      height: 25px;
      color: $textcolor-three;

      .flex {
        display: flex;
        gap: 10px;
        .headingText {
          font-weight: 500;
        }

        .closeIcon {
          font-size: $font-18;
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
  }
}

// *******Booking Details Modal*********
.bookingDetailModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $bordershadow3-color;
  z-index: 200;

  .bookingDetailModal-content {
    background-color: $background-color;
    width: 45%;
    margin: auto;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      height: 25px;
      color: $textcolor-three;

      .flex {
        display: flex;
        gap: 10px;
        .headingText {
          font-weight: 500;
        }

        .closeIcon {
          font-size: $font-18;
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
  }
}
// *******Add User Modal*********
.addUserModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $bordershadow3-color;
  z-index: 200;

  .addUserModal-content {
    background-color: $background-color;
    width: 30%;
    margin: auto;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      height: 25px;
      color: $textcolor-three;

      .flex {
        display: flex;
        gap: 10px;
        .headingText {
          font-weight: bold;
        }

        .closeIcon {
          font-size: $font-18;
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
  }
}

// *******Edit User Modal*********
.editUserModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $bordershadow3-color;
  z-index: 200;

  .editUserModal-content {
    background-color: $background-color;
    width: 30%;
    margin: auto;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      height: 25px;
      color: $textcolor-three;

      .flex {
        display: flex;
        gap: 10px;
        .headingText {
          font-weight: bold;
        }

        .closeIcon {
          font-size: $font-18;
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
  }
}

// **********Confirm Modal*********

.confirmationModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $bordershadow3-color;
  z-index: 200;

  .confirmationModal-content {
    background-color: $background-color;
    width: 30%;
    margin: auto;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      height: 25px;
      color: $textcolor-three;

      .flex {
        display: flex;
        gap: 10px;
        .headingText {
          font-weight: bold;
        }

        .closeIcon {
          font-size: $font-18;
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
  }
}
