@import "../../../resource/styles/variables/variables.module.scss";


.noDataDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
  
    .noDataText {
      margin: 0;
      font-weight: 500;
      text-align: center;
    }
  }