@import "../../../../resource/styles/variables/variables.module.scss";

.loadingbody {
  margin-bottom: 20px;


  .LPHeadingDiv {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 1px;

    .LPHeading {
      margin: 0;
      padding: 0;
      font-size: $font-13;
      font-weight: bold;
    }
  }

  .LPContent {
    margin: 0;
    margin-left: 15px;
    font-size: $font-14;
    font-family: $primary-font;
  }
}
