@import "../../../../resource/styles/variables/variables.module.scss";

.unloadingbody {
  margin-bottom: 50px;
  
  .UPHeadingDiv {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 5px;

    .UPHeading {
      margin: 0;
      padding: 0;
      font-size: $font-13;
      font-weight: bold;
    }
  }

  .UPContent {
    margin:0;
    margin-left:17px;
    font-size: $font-14;
    font-family: $primary-font;
  }
}
