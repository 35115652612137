@import "../../../resource/styles/variables/variables.module.scss";

.SpFilterBox {
  padding: 5px 10px;
  border-radius: 5px;

  .SpFilterIconsDiv {
    padding: 5px;
    border: 1px solid $boxborder-color;
    cursor: pointer;

    &:hover {
      background-color: $primary-color;
      color: $background-color;
    }

    .SpFilters {
      font-size: $font-20;
    }
  }

  .SpFilterIconsDivFix {
    padding: 5px 10px;
    padding-bottom: 4px;
    border: 1px solid $boxborder-color;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      background-color: $primary-color;
      color: $background-color;
    }

    .SpFilters {
      font-size: $font-18;
    }
  }

  .selectedIcon {
    padding: 5px;
    border: 1px solid $primary-color;
    background-color: $primary-color;
    color: $background-color;
    cursor: pointer;

    .SpFilters {
      font-size: $font-20;
    }
  }

  .selectedIconFix {
    padding: 5px 10px;
    padding-bottom: 4px;
    border: 1px solid $primary-color;
    background-color: $primary-color;
    color: $background-color;
    font-weight: bold;
    cursor: pointer;

    .SpFilters {
      font-size: $font-18;
    }
  }
}
