@import "../../../resource/styles/variables/variables.module.scss";


.filterUploadBoxContent {
  padding: 0px 10px;

  .filterUploadBoxLabel {
    font-size: $font-12;
  }

  .iconDiv {
    padding: 60px;
  }

}
.errText{
  margin: 0;
  font-size: $font-12;
  color: $textcolor-error;
  margin-left: 10px;
}