@import "../../../resource/styles/variables/variables.module.scss";

.profileBox {
  margin: 0;
  position: absolute;
  background-color: $background-color;
  box-shadow: $bordershadow2-color;
  border: 1px solid $boxborder-color;
  z-index: 100;
  width: 180px;
  top: 40px;
  right: 0;
  padding: 5px 10px;
  border-radius: 10px;

  .profileBoxHeadingDiv {
    .profileBoxHeading {
      font-size: $font-16;
      font-weight: 500;
    }
  }

  .detailsDiv {
    cursor: pointer;

    .detailsText {
      margin: 4px;
    }
    .userName {
      font-size: $font-18;
      font-weight: 500;
    }
    .contactNo {
      font-size: $font-14;
      font-weight: 500;
    }
    .company {
      font-size: $font-12;
      color: $textcolor-three;
    }
  }
  .mySettings {
    text-decoration: none;
    color: $textcolor-one;

    .mySettingsDiv {
      border: none;
      border-top: 1px solid $boxborder-color;
      border-bottom: 1px solid $boxborder-color;
      &:hover {
        background-color: $background3-color;
      }

      .mySettingsText {
        display: flex;
        align-items: center;
        gap: 5px;
        margin: 10px;
        font-size: $font-13;
        font-weight: 500;
        cursor: pointer;

        .userSettingIcon {
          font-size: $font-18;
          color: $primary-color;
        }
      }
    }
  }
  .logoutDiv {
    &:hover {
      color: $textcolor-error;
    }
    .logoutText {
      display: flex;
      align-items: center;
      gap: 5px;
      margin: 10px;
      font-size: $font-13;
      font-weight: 500;
      cursor: pointer;

      .logoutIcon {
        font-size: $font-18;
        color: $primary-color;
      }
    }
  }
}
