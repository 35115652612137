@import "../../../resource/styles/variables/variables.module.scss";

.hrLine {
  border-top: 1px solid $boxborder-color;
  margin: 2px 0;
}

.VrLine {
  border-left: 1px solid $boxborder-color;
  height: 100%; /* Adjust the height according to your requirements */
  margin: 0 2px;
}


