@import "../../../resource/styles/variables/variables.module.scss";

.spinner {
  position: relative;
  width: 10px;
  height: 10px;
  margin: 0 auto;
  border-radius: 50%;
  border: 4px solid $background-color;
  border-top: 4px solid $btnDisabled-color;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
