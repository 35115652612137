@import "../../../resource/styles/variables/variables.module.scss";

.inputboxdiv {
  position: relative;
  .label {
    font-size: $font-13;
    color: $textcolor-four;
    font-family: $third-font;
    font-weight: bold;
  }

  .inputdropdown {
    display: inline-block;
  }

  .inputbox {
    padding-left: 60px;
    width: 100%;
    height: 35px;
    line-height: 1;
    margin: 3px;
    color: $textcolor-one;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid $border-color;
    font-size: $font-15;
    border-image: initial;

    &:focus {
      outline: none !important;
    }
  }
  .txtCapitalization {
    text-transform: capitalize;
  }

  .search_input {
    font-family: inherit;
    font-size: inherit;
    background-color: $eight-color;
    border: none;
    color: $five-color;
    padding: 0.6rem 2rem;
    border-radius: 30px;
    width: 12em;
    transition: all ease-in-out 0.5s;
    margin-right: -2rem;

    &:focus,
    &:hover {
      box-shadow: 0 0 1em $background3-color;
    }
    &:focus {
      outline: none !important;
      background-color: $six-color;
    }
    &::-webkit-input-placeholder {
      font-weight: 100;
      color: $five-colorlite;
    }

    &:focus + .search__button {
      background-color: $six-color;
    }
  }

  .search__button {
    border: none;
    background-color: $eight-color;
    margin-top: 0.1em;

    &:hover {
      cursor: pointer;
    }
    .search__icon {
      height: 1.3em;
      width: 1.3em;
      fill: $textcolor-eight;
    }
  }

  .errdiv {
    width: 100%;
    margin: auto;

    .error {
      font-size: $font-12;
      color: $textcolor-three;
      margin-top: 0px;
      margin-bottom: 0px;
      white-space: nowrap;
    }
  }
  .quoteInputBox {
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    border: none;
    font-family: $third-font;
    font-size: $font-18;
    background-color: $six-color;

    &:focus {
      outline: none !important;
    }
  }

  .bidNoteInput {
    width: 90%;
    height: 50px;
  }
  .filterInputBox {
    width: 100%;
    height: 25px;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid $border-color;
    font-family: $third-font;
    font-size: $font-13;

    &:focus {
      outline: none !important;
    }
  }
}

.cnfQtyInput {
  width: 100%;
  border: none;
  border-bottom: 1px solid $border-color;

  &:focus {
    outline: none !important;
  }
}

.form__group {
  position: relative;
  padding: 11px 0 0;
  width: 93%;
  margin: 0px 10px;

  .form__field {
    position: relative;
    font-family: inherit;
    width: 100%;
    border: none;
    border-bottom: 1px solid $border-color;
    outline: 0;
    font-size: $font-14;
    padding: 0px 0px 2px 0px;
    background: transparent;
    transition: border-color 0.2s;
    text-transform: capitalize;
    margin-top: 5px;

    .editable {
      width: 100%;
      margin: 10px;
      font-size: $font-14;
      margin-top: 11px;
      font-weight: 600;
      align-items: center;
      gap: 5px;
      color: $default-color;
      border: 0px solid transparent;
      background: none;
      cursor: pointer;
      outline: none;
    }
    .editable1 {
      width: 100%;
      margin: 10px;
      font-size: $font-14;
      margin-top: 8px;
      font-weight: 600;
      align-items: center;
      gap: 5px;
      color: $eleven-color;
      border: 0px solid transparent;
      background: none;
      outline: none;
    }
    &::placeholder {
      color: transparent;
    }

    &:placeholder-shown ~ .form__label {
      font-size: $font-1;
      cursor: text;
      top: 18px;
    }

    &:valid ~ .form__label,
    &:read-only ~ .form__label {
      position: absolute;
      top: 0;
      bottom: 12px;
      display: block;
      font-weight: bold;
      transition: 0.2s;
      font-size: $font-11;
      color: $label-color-one;
    }

    &:focus ~ .form__label {
      position: absolute;
      top: 0;
      bottom: 12px;
      display: block;
      font-weight: bold;
      transition: 0.2s;
      font-size: $font-11;
      color: $label-color-one;
    }
  }
  .error_input{
    position: relative;
    font-family: inherit;
    width: 100%;
    border: none;
    border-bottom: 1px solid $textcolor-error;
    outline: 0;
    font-size: 14px;
    color: $default-color;
    padding: 0px 0px;
    background: transparent;
    transition: border-color 0.2s;
    text-transform: capitalize; /* Transforms the first character of each word to uppercase */
    &::placeholder {
      color: transparent;
    }

    

    &:placeholder-shown ~ .error_label {
      font-size: 1px;
      cursor: text;
      top: 18px;
      color:$textcolor-error;
      
      
    }
  
    &:valid ~ .error_label {
      font-weight: 600;
      transition: 0.2s;
      // top: 0;
      top: -2px;

      bottom: 13px;
      font-size: 11px;
      color:  $textcolor-error//: #999999;

      // rgb(120, 190, 120)
    }

    &:focus ~ .error_label {
      position: absolute;
      bottom: 10px;
      top: -5px;
      display: block;
      font-weight: 600;
      transition: 0.2s;
      font-size: 13px;
      color: $textcolor-error;
    }  
  }

  // .form__field_err {
  //   position: relative;
  //   font-family: inherit;
  //   width: 100%;
  //   border: none;
  //   border-bottom: 1px solid red;
  //   outline: 0;
  //   font-size: $font-14;
  //   padding: 0px 0px;
  //   background: transparent;
  //   transition: border-color 0.2s;
  //   text-transform: capitalize;

    
  //   &::placeholder {
  //     color: transparent;
  //   }

  //   &:placeholder-shown ~ .form__label {
  //     font-size: $font-1;
  //     cursor: text;
  //     top: 18px;
  //   }

  //   &:valid ~ .form__label,
  //   &:read-only ~ .form__label {
  //     position: absolute;
  //     top: 0;
  //     bottom: 12px;
  //     display: block;
  //     font-weight: bold;
  //     transition: 0.2s;
  //     font-size: $font-11;
  //     color: $label-color-one;
  //   }

  //   &:focus ~ .form__label {
  //     position: absolute;
  //     top: 0;
  //     bottom: 12px;
  //     display: block;
  //     font-weight: bold;
  //     transition: 0.2s;
  //     font-size: $font-11;
  //     color: $label-color-one;
  //   }
  // }
  .inputwidthAddload {
    width: 100%;
  }
  .inputwidthProfile {
    width: 50px;
  }
  .inputdropdown {
    position: absolute;
    z-index: 1;
    width: 98%;
  }
  .form__field:required,
  .form__field:invalid {
    box-shadow: none;
  }

  .form__label {
    position: absolute;
    display: block;
    top: 12px;
    transition: 0.2s;
    font-size: $font-13;
    color: $textcolor-eight;
    pointer-events: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .error_label {
    position: absolute;
    top: 0;
    display: block;
    top: 11px;
    transition: 0.2s;
    font-size: 13px;
    color: $textcolor-error;
    pointer-events: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    // border-bottom: 1px solid red;
   

  }
  .icondown {
    position: absolute;
    height: 10px;
    width: 12px;
    top: 60%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .iconSearch{

    position: absolute;
    height: 16px;
    width: 16px;
    top: 40%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

.Icon-inside {
  width: 208px;
  position: relative;
  display: flex;
  justify-content: center;
  .input {
    padding-left: 60px;
    width: 208px;
    height: 20px;
    line-height: 17.6px;
    margin: 5px;
    color: $textcolor-one;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid $border-color;
    font-size: $font-16;
    border-image: initial;
  }
  i {
    position: absolute;
    left: 0;
    top: 12px;
    padding: 5px 15px 9px;
  }
}

// ***************hide dd/mm/yyy placeholder******
input[type="date"]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}
input[type="date"]:focus::-webkit-datetime-edit {
  color: $five-colorlite !important;
}

// *************to hide spinners from type number*********
.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-spinners {
  -moz-appearance: textfield;
}

//**************** Change the color to your desired color ********************

input[type="checkbox"]:checked {
  accent-color: $primary-color;
}

