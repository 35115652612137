@import "../../../resource/styles/variables/variables.module.scss";

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loading-indicator-spinner {
  width: 0.7rem;
  height: 0.7rem;
  border: 0.20rem solid $background-color;
  border-top-color:$border-color;
  border-radius: 50%;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
