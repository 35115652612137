@import "../../../../resource/styles/variables/variables.module.scss";

.item {
  // border: solid 1px #ccc;
  padding: 3px 5px 3px 3px;
  margin: 0px;
  text-transform: capitalize;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: normal;
  width: 100%;
  overflow: hidden;
  display: block;
  // height: 20px;
  display: flex;
  align-items: center;
  margin-left: 2px;
  // &:hover {
  //   background-color: green;
  //   color: white;
  // }
  // &:hover {
  //   background-color: #ccc;
  // }
}

