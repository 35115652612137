@import "../../../../../resource/styles/variables/variables.module.scss";

.viewClaimModalDiv {
  border-top: 1px solid $boxborder-color;

  .totalDiv {
    margin-bottom: 5px;
    border-top: 1px solid $boxborder-color;
  }

  .eachContentDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-right: 20px;
    margin-left: 10px;
    .contentMainHeading {
      margin: 10px 0px 5px 10px;
      font-size: $font-14;
      font-weight: bold;
      color: $primary-color;
    }
    .contentHeading {
      width: 50%;
      margin: 5px 0px 3px 10px;
      font-size: $font-14;
      font-weight: 500;
    }
    .contentText {
      margin: 0;
      font-size: $font-15;
      margin-right: 10px;
    }
    .totalText {
      font-weight: 500;
    }
  }
}

.rejectClaimModalDiv {
  border-top: 1px solid $boxborder-color;
  .eachContentDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-right: 20px;
    margin-top: 5px;
    margin-bottom: 10px;

    .contentHeading {
      width: 50%;
      margin: 5px 0px 3px 10px;
      font-size: $font-14;
      font-weight: 500;
    }
    .contentText {
      margin: 0;
      font-size: $font-15;
      font-weight: 500;
      margin-left: 10px;
    }
  }
}
