@import "../../../resource/styles/variables/variables.module.scss";

.dpLabel {
  margin: 0;
  font-size: $font-11;
  color: $label-color-one;
  font-family: $third-font;
  font-weight: bold;
  padding-left: 10px;
}
.date-picker-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 6px;
  margin-top: -2px;
  // border: 1px solid black;
}
.date-picker-container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 6px;
  margin-top: -2px;
}
.react-datepicker-wrapper {
  display: flex;
}
.red-border {
  border-bottom: 1px solid red;
}
input {
  padding: 1px 1px 1px 4px;
  width: 93%;
  height: 16px;
  border: none;
  font-size: 14px;
  cursor: pointer;
  border-bottom: 1px solid $border-color;
}
// .react-datepicker__input-container1 input {
//   padding: 1px 1px 1px 4px;
//   width: 93%;
//   height: 16px;
//   border: none;
//   font-size: 14px;
//   cursor: pointer;
//   border-bottom: 1px solid red;
// }
.react-datepicker__input-container input::placeholder {
  font-size: $font-13;
  white-space: nowrap;
  color: $textcolor-eight;
}

.react-datepicker__input-container input:focus {
  outline: none;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker__month-container {
  width: 180px;
}

.react-datepicker__month {
  margin: 0 5px;
}

.react-datepicker__day-names,
.react-datepicker__week {
  display: flex;
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: $textcolor-one;
  cursor: pointer;
}

.react-datepicker__day-name {
  color: $textcolor-eight;
}

.react-datepicker__day--today {
  font-weight: bold;
}

.react-datepicker__day--selected {
  background-color: $four-color;
  color: $background-color;
  font-weight: bold;
  border-radius: 50%;
}

.react-datepicker__day--disabled {
  color: #ccc;
  cursor: default;
}

.react-datepicker__day--prev-month {
  color: #ccc;
}

.errmessage {
  margin: 0px;
  font-size: $font-12;
  color: $textcolor-three;
  white-space: nowrap;
}
