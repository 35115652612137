@import "../../../resource/styles/variables/variables.module.scss";

.autoScrollingLineDiv {
  width: 100%;
  overflow: hidden;

  .autoScrollingLineBox {
    display: inline-block;
    white-space: nowrap;

    .autoScrollingLineText {
      margin:0;
      font-size: $font-14;
      font-weight: 500;
    }
  }
}
