@import "../../../resource/styles/variables/variables.module.scss";

.expTime {
  font-size: $font-11;
  margin: 0px;
}

.expRunTime {
    font-size: $font-11;
    margin: 0px;
    color: green;
  }