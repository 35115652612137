@import "../../../../resource/styles/variables/variables.module.scss";

.loadDeatailsModal {
  padding: 5px 10px;
  border: 1px solid $boxborder-color;

  .flex {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;

    .loadDetailsFlex {
      width: 50%;
    }
    .vehicleDetailsFlex {
      width: 50%;
    }
  }
  .notesBox {
    margin-bottom: 10px;
  }
  .bidNoteBox {
    margin-bottom: 10px;
  }
  .modalHeading {
    margin: 0;
    font-size: $font-14;
    font-weight: bold;
    color: $textcolor-three;
    margin-top: 5px;
  }
  .eachLoadDetailsDiv {
    justify-content: left;

    .contentText {
      justify-content: left;
      margin: 0;
      font-size: $font-14;
      font-weight: 500;
      width: 35%;
      margin-bottom: 5px;
    }
    .loadDetailsData {
      margin: 0;
      font-size: $font-14;
      font-weight: 400;
      width: 65%;
      margin-bottom: 5px;
    }
  }
  .contentText {
    justify-content: left;
    margin: 0;
    font-size: $font-14;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .odc {
    margin: 0;
    font-size: $font-14;
    font-weight: 500;
    color: $textcolor-three;
  }
  .dg {
    margin: 0;
    font-size: $font-14;
    font-weight: 500;
    color: $textcolor-three;
  }
}
