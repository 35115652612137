@import "../../../../resource/styles/variables/variables.module.scss";

.SpPointofcontact {
  .pocHeading {
    text-align: right;
    margin: 0;
    font-size: $font-14;
  }
  .pocText {
    text-align: right;
    margin: 0;
    font-size: $font-14;
    font-style: italic;
    font-weight: 500;
  }
}
