@import "../../../../resource/styles/variables/variables.module.scss";

.accStatementDiv {
  width: 100%;
  margin: auto;

  .accStatementHeaderDiv {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // gap: 15%;
    // border: 1px solid;

    .accStatementHeader {
      text-align: center;
      font-size: $h1;
      font-weight: bold;
      // border: 1px solid green;
    }
    .dateFilterdiv {
      width: 33%;
      // margin: auto;
      display: flex;
      gap: 5px;

      .gobtn {
        padding-top: 0px;
        height: 25px;
        margin-top: 8px;
      }
    }
  }

  .accDataTable {
    width: 95%;
    margin: auto;
  }
  .footerASDiv {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border: 1px solid;
    .paginationdiv {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      // border: 1px solid red;
    }
    .downloadXlsxIcon {
      width: auto;
      margin: auto;
      background-color: $twelve-color;
      color: $background-color;
      height: 30px;
      border: none;
      font-weight: 500;
      cursor: pointer;
      padding: 0px 5px;
      border-radius: 5px;
      margin-top: 5px;
    }
  }
  .accStatementFooterDiv {
    width: 95%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 20px;

    .footerHeading {
      font-size: $font-13;
      font-weight: bold;
    }
    .footerContent {
      font-size: $font-12;
      font-weight: bold;
    }

    .footerDivOne {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;
      background-color: $three-color;
      margin-top: 2px;
      border-radius: 5px;

      p {
        margin: 0;
        padding: 0;
      }
    }
    .footerDivTwo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;
      background-color: $three-colorlite;
      margin-top: 2px;
      border-radius: 5px;

      p {
        margin: 0;
        padding: 0;
      }
    }
    .footerDivThree {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;
      background-color: $secondary-colorlite;
      margin-top: 2px;
      border-radius: 5px;

      p {
        margin: 0;
        padding: 0;
      }
    }
  }
}
