@import "../../../../resource/styles/variables/variables.module.scss";

.SpQuote {
  margin-top: 0px;

  .quotePriceBox {
    .quoteprice {
      width: 120px;
      padding: 13px 10px;
      background-color: $six-color;
      box-shadow: $bordershadow-color;
      border-radius: 10px;
      margin-top: 10px;
      gap: 10px;

      .l1 {
        font-size: $font-20;
        color: $textcolor-two;
        font-weight: bold;
      }
    }

    .ceilingprice {
      margin: 0;
      color: $textcolor-eight;
      font-size: $font-12;
      margin-top: 3px;
      margin-bottom: 5px;
      margin-left: 5px;
      font-weight: 500;
      text-align: center;

      .infoIconDiv {
        cursor: pointer;
        display: flex;

        .infoIcon {
          font-size: $font-14;
        }
      }
    }
  }
}
